import React, { FunctionComponent } from 'react'
import { Box, Flex, GridItem, Show, Text } from '@chakra-ui/react'
import Colors from '../theme/Colors'
import { useTranslation } from 'react-i18next'
import { PRO_PRODUCT_ID, subscriptionActions } from '../../domain/actions/subscription/subscriptionActions'
import { useDispatch, useStore } from 'react-redux'
import mixpanel from 'mixpanel-browser'
import { AARRRTrackEvents, PaymentTrackEvents } from '../../utils/trackEvents'

const PremiumBanner: FunctionComponent = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const store = useStore()

    const handleBannerClicked = async () => {
        mixpanel.track(PaymentTrackEvents.PAYMENTS_UPGRADE_BANNER_CLICKED)
        mixpanel.track(AARRRTrackEvents.RET_CHECKOUT_PAGE_VISIT, {
            plan: 'pro',
        })
        const checkoutUrl = await subscriptionActions(dispatch).getCheckoutSession(PRO_PRODUCT_ID, store)
        window.location.replace(checkoutUrl)
    }

    return (
        <GridItem
            colSpan={[3, 3, 2]}
            borderRadius={12}
            py={5}
            px={7}
            cursor={'pointer'}
            bgGradient={`linear(93deg, #CBBFF9 0%, #A894F1CC 100%)`}
            transition={'opacity'}
            transitionDuration={'0.2s'}
            _hover={{
                opacity: 0.8,
            }}
            onClick={handleBannerClicked}>
            <Flex justifyContent={'space-between'}>
                <Flex direction={'column'} justifyContent={'space-between'} flex={1} maxW={'250px'}>
                    <Text fontSize={['xl', '2xl']} fontWeight={'bold'} color={'#5e60de'}>
                        {t('premiumBanner.title')}
                    </Text>
                    <Text fontSize={['sm', 'md']} color={Colors.text} lineHeight={'21px'}>
                        <Show above="sm">{t('premiumBanner.feature.md')}</Show>
                        <Show below="sm">{t('premiumBanner.feature.sm')}</Show>
                    </Text>
                </Flex>
                <Box flex={1} maxW={'250px'} ml={'12px'}>
                    <Text
                        fontSize={['sm', 'md']}
                        fontWeight={'bold'}
                        lineHeight={'21px'}
                        color={Colors.text}
                        textAlign={'right'}>
                        <Show above="sm">{t('premiumBanner.offer.md')}</Show>
                        <Show below="sm">{t('premiumBanner.offer.sm')}</Show>
                    </Text>
                    <Text
                        fontSize={['2xl', '3xl']}
                        fontWeight={'bold'}
                        color={'#5e60de'}
                        textAlign={'right'}
                        mt={'12px'}>
                        $3
                        <Text fontSize={'md'} as={'span'} opacity={0.6}>
                            {` ${t('premiumBanner.month')}`}
                        </Text>
                    </Text>
                </Box>
            </Flex>
        </GridItem>
    )
}

export default PremiumBanner
