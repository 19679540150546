import React, { FunctionComponent, useRef } from 'react'
import {
    Box,
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
} from '@chakra-ui/react'
import Colors from '../theme/Colors'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector, useStore } from 'react-redux'
import {
    PRO_PRODUCT_ID,
    subscriptionActions,
    ULTRA_PRODUCT_100K_ID,
    ULTRA_PRODUCT_50K_ID,
    ULTRA_PRODUCT_ID,
} from '../../domain/actions/subscription/subscriptionActions'
import mixpanel from 'mixpanel-browser'
import { AARRRTrackEvents, PaymentTrackEvents } from '../../utils/trackEvents'
import { StateType } from '../../domain/state/types'

interface Props {
    isOpen: boolean
    onClose: () => void
}

const UpgradeDialog: FunctionComponent<Props> = ({ isOpen, onClose }) => {
    const initialRef = useRef(null)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const store = useStore()

    const subscriptionData = useSelector((state: StateType) => {
        return state.subscriptionReducer.subscriptionData
    })

    const handleCheckoutClicked = async (productId: string) => {
        onClose()
        const checkoutUrl = await subscriptionActions(dispatch).getCheckoutSession(productId, store)
        window.location.replace(checkoutUrl)
    }

    return (
        <Modal initialFocusRef={initialRef} size={'xl'} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent marginX={4}>
                <ModalHeader>{t('upgradePlanDialog.title')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <Text>{t('upgradePlanDialog.description')}</Text>
                    {/* TODO - Adapt to enable downgrade */}
                    {subscriptionData?.plan === 0 && (
                        <Box
                            borderRadius={12}
                            pt={4}
                            pb={6}
                            px={5}
                            mt={4}
                            cursor={'pointer'}
                            bgGradient={`linear(93deg, #CBBFF9 0%, #A894F1CC 100%)`}
                            transition={'opacity'}
                            transitionDuration={'0.2s'}
                            _hover={{
                                opacity: 0.8,
                            }}
                            onClick={() => {
                                mixpanel.track(PaymentTrackEvents.PAYMENTS_UPGRADE_TO_PRO_BUTTON_CLICKED)
                                mixpanel.track(AARRRTrackEvents.RET_CHECKOUT_PAGE_VISIT, {
                                    plan: 'pro',
                                })

                                handleCheckoutClicked(PRO_PRODUCT_ID)
                            }}>
                            <Flex justifyContent={'space-between'} alignItems={'center'}>
                                <Text fontSize={'xl'} fontWeight={'bold'} color={Colors.text}>
                                    {t('upgradePlanDialog.proPlan.title')}
                                </Text>
                                <Text fontSize={'2xl'} fontWeight={'bold'} color={'#5e60de'} textAlign={'right'}>
                                    {t('upgradePlanDialog.proPlan.price')}
                                    <Text fontSize={'md'} as={'span'} opacity={0.6}>
                                        {t('upgradePlanDialog.month')}
                                    </Text>
                                </Text>
                            </Flex>
                            <Text color={Colors.text} opacity={0.75} mt={'-4px'}>
                                {t('upgradePlanDialog.proPlan.description1')}
                            </Text>
                            <Text color={Colors.text} opacity={0.75} mt={'-4px'}>
                                {t('upgradePlanDialog.proPlan.description2')}
                            </Text>
                        </Box>
                    )}
                    {(subscriptionData?.plan === 0 || subscriptionData?.plan === 1) && (
                        <Box
                            borderRadius={12}
                            borderColor={'#A894F1'}
                            borderWidth={2}
                            pt={4}
                            pb={6}
                            px={5}
                            mt={4}
                            cursor={'pointer'}
                            transition={'background'}
                            transitionDuration={'0.2s'}
                            _hover={{
                                backgroundColor: '#A894F122',
                            }}
                            onClick={() => {
                                mixpanel.track(PaymentTrackEvents.PAYMENTS_UPGRADE_TO_ULTRA_BUTTON_CLICKED)
                                mixpanel.track(AARRRTrackEvents.RET_CHECKOUT_PAGE_VISIT, {
                                    plan: 'ultra',
                                })

                                handleCheckoutClicked(ULTRA_PRODUCT_ID)
                            }}>
                            <Flex justifyContent={'space-between'} alignItems={'center'}>
                                <Text fontSize={'xl'} fontWeight={'bold'} color={Colors.text}>
                                    {t('upgradePlanDialog.ultraPlan.title')}
                                </Text>
                                <Text fontSize={'2xl'} fontWeight={'bold'} color={'#5e60de'} textAlign={'right'}>
                                    {t('upgradePlanDialog.ultraPlan.price')}
                                    <Text fontSize={'md'} as={'span'} opacity={0.6}>
                                        {t('upgradePlanDialog.month')}
                                    </Text>
                                </Text>
                            </Flex>
                            <Text color={Colors.text} opacity={0.75} mt={'-4px'}>
                                {t('upgradePlanDialog.ultraPlan.description1')}
                            </Text>
                            <Text color={Colors.text} opacity={0.75} mt={'-4px'}>
                                {t('upgradePlanDialog.ultraPlan.description2')}
                            </Text>
                        </Box>
                    )}
                    {(subscriptionData?.plan === 0 || subscriptionData?.plan === 1 || subscriptionData?.plan === 2) && (
                        <Box
                            borderRadius={12}
                            borderColor={'#A894F1'}
                            borderWidth={2}
                            pt={4}
                            pb={6}
                            px={5}
                            mt={4}
                            cursor={'pointer'}
                            transition={'background'}
                            transitionDuration={'0.2s'}
                            _hover={{
                                backgroundColor: '#A894F122',
                            }}
                            onClick={() => {
                                mixpanel.track(PaymentTrackEvents.PAYMENTS_UPGRADE_TO_ULTRA_BUTTON_CLICKED)
                                mixpanel.track(AARRRTrackEvents.RET_CHECKOUT_PAGE_VISIT, {
                                    plan: 'ultra-50K',
                                })

                                handleCheckoutClicked(ULTRA_PRODUCT_50K_ID)
                            }}>
                            <Flex justifyContent={'space-between'} alignItems={'center'}>
                                <Text fontSize={'xl'} fontWeight={'bold'} color={Colors.text}>
                                    {t('upgradePlanDialog.ultra50KPlan.title')}
                                </Text>
                                <Text fontSize={'2xl'} fontWeight={'bold'} color={'#5e60de'} textAlign={'right'}>
                                    {t('upgradePlanDialog.ultra50KPlan.price')}
                                    <Text fontSize={'md'} as={'span'} opacity={0.6}>
                                        {t('upgradePlanDialog.month')}
                                    </Text>
                                </Text>
                            </Flex>
                            <Text color={Colors.text} opacity={0.75} mt={'-4px'}>
                                {t('upgradePlanDialog.ultra50KPlan.description1')}
                            </Text>
                            <Text color={Colors.text} opacity={0.75} mt={'-4px'}>
                                {t('upgradePlanDialog.ultra50KPlan.description2')}
                            </Text>
                        </Box>
                    )}
                    <Box
                        borderRadius={12}
                        borderColor={'#A894F1'}
                        borderWidth={2}
                        pt={4}
                        pb={6}
                        px={5}
                        mt={4}
                        cursor={'pointer'}
                        transition={'background'}
                        transitionDuration={'0.2s'}
                        _hover={{
                            backgroundColor: '#A894F122',
                        }}
                        onClick={() => {
                            mixpanel.track(PaymentTrackEvents.PAYMENTS_UPGRADE_TO_ULTRA_BUTTON_CLICKED)
                            mixpanel.track(AARRRTrackEvents.RET_CHECKOUT_PAGE_VISIT, {
                                plan: 'ultra-100K',
                            })

                            handleCheckoutClicked(ULTRA_PRODUCT_100K_ID)
                        }}>
                        <Flex justifyContent={'space-between'} alignItems={'center'}>
                            <Text fontSize={'xl'} fontWeight={'bold'} color={Colors.text}>
                                {t('upgradePlanDialog.ultra100KPlan.title')}
                            </Text>
                            <Text fontSize={'2xl'} fontWeight={'bold'} color={'#5e60de'} textAlign={'right'}>
                                {t('upgradePlanDialog.ultra100KPlan.price')}
                                <Text fontSize={'md'} as={'span'} opacity={0.6}>
                                    {t('upgradePlanDialog.month')}
                                </Text>
                            </Text>
                        </Flex>
                        <Text color={Colors.text} opacity={0.75} mt={'-4px'}>
                            {t('upgradePlanDialog.ultra100KPlan.description1')}
                        </Text>
                        <Text color={Colors.text} opacity={0.75} mt={'-4px'}>
                            {t('upgradePlanDialog.ultra100KPlan.description2')}
                        </Text>
                    </Box>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={onClose}>{t('common.cancel')}</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default UpgradeDialog
