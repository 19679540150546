import React, { FunctionComponent } from 'react'
import { Box, Flex, IconButton, Text, Tooltip, useToast } from '@chakra-ui/react'
import Colors from '../../theme/Colors'
import { useTranslation } from 'react-i18next'
import { AiOutlineCopy, AiOutlineLink, AiOutlineMail } from 'react-icons/ai'
import { ExecutionTrackEvents } from '../../../utils/trackEvents'
import mixpanel from 'mixpanel-browser'
import { ExecutionMode } from '../../../domain/models/ExecutionMode'

interface Props {
    data: string
    mode: ExecutionMode
}

const ExecutionDetailDialogItem: FunctionComponent<Props> = ({ data, mode }) => {
    const toast = useToast()
    const { t } = useTranslation()

    const handleCopyResults = () => {
        navigator.clipboard.writeText(data)
        mixpanel.track(ExecutionTrackEvents.EXECUTION_ITEM_COPIED)
        toast({
            title: t('successFeedback.copy.title'),
            description: t('successFeedback.copy.description'),
            status: 'success',
            position: 'bottom',
            duration: 3000,
            isClosable: true,
        })
    }

    const openLink = () => {
        window.open(data)
    }

    const openMailto = () => {
        window.open(`mailto:${data}`)
    }

    const onTextClicked = () => {
        if (mode === ExecutionMode.LINKS_ABSOLUTE || mode === ExecutionMode.LINKS_RELATIVE) {
            openLink()
        } else if (mode === ExecutionMode.EMAILS) {
            openMailto()
        }
    }

    const renderSecondActionButton = () => {
        if (mode === ExecutionMode.LINKS_ABSOLUTE || mode === ExecutionMode.LINKS_RELATIVE) {
            return (
                <Tooltip label={t('executionItem.openLink')} placement={'left'} openDelay={500}>
                    <IconButton
                        color={Colors.text}
                        colorScheme="ctas"
                        aria-label={t('executionItem.openLink')}
                        icon={<AiOutlineLink />}
                        size={'md'}
                        onClick={(event) => {
                            event.stopPropagation()
                            openLink()
                        }}
                    />
                </Tooltip>
            )
        } else if (mode === ExecutionMode.EMAILS) {
            return (
                <Tooltip label={t('executionItem.openEmailLink')} placement={'left'} openDelay={500}>
                    <IconButton
                        color={Colors.text}
                        colorScheme="ctas"
                        aria-label={t('executionItem.openEmailLink')}
                        icon={<AiOutlineMail />}
                        size={'md'}
                        onClick={(event) => {
                            event.stopPropagation()
                            openMailto()
                        }}
                    />
                </Tooltip>
            )
        } else {
            return null
        }
    }

    return (
        <Box
            backgroundColor={'#f8f5ff'}
            borderRadius={8}
            my={'12px'}
            py={2}
            pl={4}
            pr={2}
            // border={`1px solid ${Colors.primary['300']}`}
        >
            <Flex justifyContent={'space-between'} alignItems={'center'}>
                <Box>
                    <Text
                        fontSize={'md'}
                        fontWeight={'600'}
                        color={Colors.text}
                        noOfLines={1}
                        cursor={
                            mode !== ExecutionMode.TEXTS && mode !== ExecutionMode.TEXTS_INDIVIDUAL
                                ? 'pointer'
                                : 'inherit'
                        }
                        onClick={onTextClicked}>
                        {data}
                    </Text>
                </Box>
                <Box>
                    {renderSecondActionButton()}
                    <Tooltip label={t('executionItem.copyTooltip')} placement={'left'} openDelay={500}>
                        <IconButton
                            color={Colors.text}
                            colorScheme="ctas"
                            aria-label={t('executionItem.copyTooltip')}
                            icon={<AiOutlineCopy />}
                            size={'md'}
                            ml={2}
                            onClick={(event) => {
                                event.stopPropagation()
                                handleCopyResults()
                            }}
                        />
                    </Tooltip>
                </Box>
            </Flex>
        </Box>
    )
}

export default ExecutionDetailDialogItem
