import { createClient } from '@supabase/supabase-js'
import mixpanel from 'mixpanel-browser'
import { AuthTrackEvents } from '../../utils/trackEvents'

// Create a single supabase client for interacting with your database
export const supabase = createClient(
    process.env.REACT_APP_SUPABASE_URL || '',
    process.env.REACT_APP_SUPABASE_KEY || '',
    {
        auth: {
            storage: localStorage,
            autoRefreshToken: true,
            persistSession: true,
            detectSessionInUrl: true,
        },
    },
)

export const supabaseLogout = (history: any) => {
    supabase.auth.signOut().then(() => {
        mixpanel.track(AuthTrackEvents.LOGOUT)
        mixpanel.reset()
        history.push('/login')
    })
}

export const getSupabaseSession = async (history?: any) => {
    const supabaseSession = await supabase.auth.getSession()
    if (supabaseSession?.data?.session) return supabaseSession.data.session

    if (history) {
        history.push('/login')
    }
}
