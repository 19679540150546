import React, { FunctionComponent } from 'react'
import {
    Button,
    HStack,
    Link,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    ModalHeader,
    Text,
    useToast,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import Colors from '../../theme/Colors'
import { cleanUrl } from '../../../utils/fucntions'
import mixpanel from 'mixpanel-browser'
import { ExecutionTrackEvents } from '../../../utils/trackEvents'
import { ExecutionMode } from '../../../domain/models/ExecutionMode'
import ExecutionDetailDialogItem from './ExecutionDetailDialogItem'

interface Props {
    websiteUrl: string
    content: string
    mode: ExecutionMode
    onClose: () => void
}

const ExecutionDetailDialogContent: FunctionComponent<Props> = ({ websiteUrl, content, mode, onClose }) => {
    const toast = useToast()
    const { t } = useTranslation()

    const handleCopyApiKey = () => {
        if (content) {
            navigator.clipboard.writeText(content)
            mixpanel.track(ExecutionTrackEvents.EXECUTION_ITEM_COPIED)
            toast({
                title: t('successFeedback.copy.title'),
                description: t('successFeedback.copy.description'),
                status: 'success',
                position: 'bottom',
                duration: 3000,
                isClosable: true,
            })
        }
    }

    const renderContentData = () => {
        if (mode === ExecutionMode.TEXTS) {
            return (
                <Text color={Colors.text} fontSize={'md'} mt={'4px'}>
                    {content || t('executionDialog.noCopies')}
                </Text>
            )
        } else {
            const contentParsed: string[] = JSON.parse(content)
            return contentParsed.map((item, index) => {
                return <ExecutionDetailDialogItem key={item + index} data={item} mode={mode} />
            })
        }
    }

    return (
        <>
            <ModalHeader>{t('executionDialog.title')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
                <Text color={Colors.textSecondary} fontWeight={'bold'} fontSize={'md'}>
                    {t('executionDialog.websiteLabel')}
                </Text>
                <Link
                    href={websiteUrl}
                    isExternal
                    onClick={() => {
                        mixpanel.track(ExecutionTrackEvents.EXECUTION_ITEM_WEBSITE_CLICKED)
                    }}>
                    <Text color={Colors.text} fontSize={'md'} mt={'4px'} textDecor={'underline'}>
                        {cleanUrl(websiteUrl)}
                    </Text>
                </Link>
                <HStack mt={'16px'} justifyContent={'space-between'}>
                    <Text color={Colors.textSecondary} fontWeight={'bold'} fontSize={'md'}>
                        {t('executionDialog.copiesLabel')}
                    </Text>
                    <Text
                        color={Colors.primary[900]}
                        fontSize={'md'}
                        cursor={'pointer'}
                        onClick={handleCopyApiKey}
                        transition={'color'}
                        transitionDuration={'0.2s'}
                        _hover={{
                            color: Colors.primary['600'],
                        }}>
                        {t('common.copy')}
                    </Text>
                </HStack>
                {renderContentData()}
            </ModalBody>

            <ModalFooter>
                <Button onClick={onClose}>{t('common.close')}</Button>
            </ModalFooter>
        </>
    )
}

export default ExecutionDetailDialogContent
