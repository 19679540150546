import {
    Box,
    Button,
    Card,
    Divider,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Text,
} from '@chakra-ui/react'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Field, Form, Formik, FormikValues } from 'formik'
import Colors from '../../theme/Colors'
import { use100vh } from 'react-div-100vh'
import { authRepository } from '../../../data/repositories/authRepository'

export const RecoverPasswordScreen = () => {
    const height = use100vh()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [loginError, setLoginError] = useState<string | null>(null)

    useEffect(() => {
        if (loginError) {
            setTimeout(() => {
                setLoginError(null)
            }, 3000)
        }
    }, [loginError])

    const goToApp = () => {
        history.push('/')
    }

    const signIn = (values: FormikValues) => {
        setIsLoading(true)
        const { password } = values

        if (password) {
            authRepository()
                .resetPassword(password)
                .then((res: any) => {
                    setIsLoading(false)
                    goToApp()
                })
                .catch((err: any) => {
                    setIsLoading(false)
                    setLoginError('An error occurred, please try again later.')
                })
        }
    }

    const validateForm = (values: FormikValues) => {
        const errors: any = {}

        if (!values.password) {
            errors.password = 'Required'
        }

        if (!values.confirmPassword) {
            errors.confirmPassword = 'Required'
        }

        if (values.password && values.confirmPassword && values.password !== values.confirmPassword) {
            errors.password = "Passwords don't match"
            errors.confirmPassword = "Passwords don't match"
        }

        return errors
    }

    return (
        <Flex h={height || '100vh'}>
            <Box
                flex={1}
                bgGradient={`linear(to-br, ${Colors.backgroundGradient.left} 0%, ${Colors.backgroundGradient.right} 100%)`}
                p={2}
                overflowY={'hidden'}>
                <Flex
                    h={'100%'}
                    maxW={{ xl: '1200px' }}
                    m="0 auto"
                    p={2}
                    maxH={'100%'}
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}>
                    <Box overflowY={'auto'} className={'no-scrollbar '}>
                        <Card
                            bg={'white'}
                            w={'100%'}
                            minW={'300px'}
                            maxW={'400px'}
                            m={'0 auto'}
                            p={8}
                            borderRadius={12}>
                            <Flex justifyContent={'center'}>
                                <Link to={'/login'}>
                                    <img
                                        alt="Scraptio"
                                        src="https://scraptio.s3.eu-west-3.amazonaws.com/logo-dark.png"
                                        style={{ maxWidth: 200 }}
                                    />
                                </Link>
                            </Flex>
                            <Box textAlign={'center'}>
                                <Text fontSize="lg" color={'gray.500'}>
                                    Set your new password and enjoy Scraptio
                                </Text>
                            </Box>
                            <Divider my={6} borderColor={'gray.200'} />
                            <Formik
                                initialValues={{
                                    password: '',
                                    confirmPassword: '',
                                }}
                                validate={validateForm}
                                validateOnChange={false}
                                validateOnBlur={false}
                                onSubmit={(values, actions) => {
                                    signIn({ ...values })
                                }}>
                                {({ errors }) => (
                                    <Form>
                                        <Field name={'password'}>
                                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                            {/* @ts-ignore */}
                                            {({ field }) => (
                                                <FormControl mb="16px" isInvalid={!!errors?.password}>
                                                    <FormLabel
                                                        display="flex"
                                                        ms="4px"
                                                        fontSize="sm"
                                                        fontWeight="500"
                                                        mb="8px">
                                                        {'Password'} <Text color={'red.400'}>*</Text>
                                                    </FormLabel>
                                                    <Input
                                                        {...field}
                                                        isRequired={true}
                                                        variant="outline"
                                                        fontSize="sm"
                                                        placeholder={'Your password...'}
                                                        fontWeight="500"
                                                        size="lg"
                                                        type={'password'}
                                                        focusBorderColor="brand.500"
                                                    />
                                                    <FormErrorMessage>{errors.password}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name={'confirmPassword'}>
                                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                            {/* @ts-ignore */}
                                            {({ field }) => (
                                                <FormControl mb="16px" isInvalid={!!errors.confirmPassword}>
                                                    <FormLabel
                                                        display="flex"
                                                        ms="4px"
                                                        fontSize="sm"
                                                        fontWeight="500"
                                                        mb="8px">
                                                        {'Confirm password'} <Text color={'red.400'}>*</Text>
                                                    </FormLabel>
                                                    <Input
                                                        {...field}
                                                        isRequired={true}
                                                        variant="outline"
                                                        fontSize="sm"
                                                        placeholder={'Confirm your password...'}
                                                        fontWeight="500"
                                                        size="lg"
                                                        type={'password'}
                                                        focusBorderColor="brand.500"
                                                    />
                                                    <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Button
                                            w={'100%'}
                                            colorScheme={loginError ? 'red' : 'brand'}
                                            variant="outline"
                                            borderColor={loginError ? 'red.300' : 'brand.600'}
                                            color={loginError ? 'red.300' : 'brand.600'}
                                            isLoading={isLoading}
                                            transition={'background-color'}
                                            transitionDuration={'0.2s'}
                                            _hover={{
                                                backgroundColor: Colors.primary['100'],
                                            }}
                                            loadingText="Saving your new password..."
                                            type="submit">
                                            {loginError ? loginError : 'Save new password'}
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        </Card>
                    </Box>
                </Flex>
            </Box>
        </Flex>
    )
}

export default RecoverPasswordScreen
