import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { subscriptionReducer } from './state/reducers/subscriptionReducer'
import { StateType } from './state/types'
import { historyReducer } from './state/reducers/historyReducer'
import { apiKeysReducer } from './state/reducers/apiKeysReducer'
import { executionReducer } from './state/reducers/executionReducer'
import { scraperReducer } from './state/reducers/scraperReducer'

const reducers = {
    subscriptionReducer,
    historyReducer,
    apiKeysReducer,
    executionReducer,
    scraperReducer,
}

export default function configureStore(initialState: StateType) {
    const middlewares = [thunkMiddleware]
    const middlewareEnhancer = applyMiddleware(...middlewares)

    const enhancers = [middlewareEnhancer]
    const composedEnhancers = compose(...enhancers)
    const combinedReducers = combineReducers({
        ...reducers,
    })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const store = createStore(combinedReducers, initialState, composedEnhancers)

    return store
}
