import React, { FunctionComponent } from 'react'
import { Avatar, Box, Flex, Menu, MenuButton, MenuItem, MenuList, Show, useMediaQuery } from '@chakra-ui/react'
import Colors from '../../theme/Colors'
import { useHistory } from 'react-router-dom'
import { supabaseLogout } from '../../../data/services/supabaseService'
import QuotaBar from '../QuotaBar'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { StateType } from '../../../domain/state/types'
import { getPlanCredits, subscriptionActions } from '../../../domain/actions/subscription/subscriptionActions'
import mixpanel from 'mixpanel-browser'
import { AARRRTrackEvents, PaymentTrackEvents, SupportTrackEvents } from '../../../utils/trackEvents'

interface Props {
    openUpgradeDialog: () => void
}

const Header: FunctionComponent<Props> = ({ openUpgradeDialog }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()

    const [isTabletOrLarger] = useMediaQuery('(min-width: 767px)')

    const subscriptionData = useSelector((state: StateType) => {
        return state.subscriptionReducer.subscriptionData
    })

    const handleCheckoutClicked = () => {
        mixpanel.track(PaymentTrackEvents.PAYMENTS_UPGRADE_BUTTON_CLICKED)
        openUpgradeDialog()
    }

    const handleEmptyQuotaUpgradeClicked = async () => {
        mixpanel.track(PaymentTrackEvents.PAYMENTS_UPGRADE_BUTTON_CLICKED)
        openUpgradeDialog()
        // if (subscriptionData?.customerId && subscriptionData?.plan !== 0) {
        //     const customerPortalUrl = await subscriptionActions(dispatch).getCustomerPortalSession(
        //         subscriptionData.customerId,
        //     )
        //     window.location.replace(customerPortalUrl)
        // } else {
        //     openUpgradeDialog()
        // }
    }

    const handleSupportClicked = () => {
        mixpanel.track(SupportTrackEvents.SUPPORT_BUTTON_CLICKED)
        const supportUrl = 'https://scraptio.notion.site/Scraptio-Docs-6414064fb16342098de4e251cf9f89f5?pvs=4'
        window.open(supportUrl, '_blank')
    }

    const handleBillingItemClicked = async () => {
        if (subscriptionData?.customerId) {
            mixpanel.track(PaymentTrackEvents.PAYMENTS_CUSTOMER_PORTAL_CLICKED)
            const customerPortalUrl = await subscriptionActions(dispatch).getCustomerPortalSession(
                subscriptionData.customerId,
            )
            window.location.replace(customerPortalUrl)
        }
    }

    const showQuotaBar = () => {
        return true //subscriptionData?.plan === 0 || subscriptionData?.plan === 1
    }

    return (
        <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            w="100%"
            mb={6}
            p={4}
            pl={[4, 4, 8]}
            backgroundColor={Colors.whiteOpacity}
            borderRadius={12}>
            <Flex align="center">
                <img
                    alt="Scraptio"
                    src="https://scraptio.s3.eu-west-3.amazonaws.com/scraptio-icon-name.svg"
                    style={isTabletOrLarger ? { maxWidth: 200, marginLeft: -10, marginRight: -10 } : { maxWidth: 160 }}
                />
            </Flex>

            <Box display={{ base: 'block' }} flexBasis={{ base: 'auto' }}>
                <Flex align={'center'} justify={'center'} direction={'row'} pt={0}>
                    {subscriptionData && showQuotaBar() && (
                        <Show above={'md'}>
                            <QuotaBar
                                max={getPlanCredits(subscriptionData.plan)}
                                current={Math.max(
                                    getPlanCredits(subscriptionData.plan) - subscriptionData.usedCredits,
                                    0,
                                )}
                                openUpgradeDialog={handleEmptyQuotaUpgradeClicked}
                            />
                        </Show>
                    )}
                    <Menu>
                        <MenuButton>
                            <Avatar size="sm" color={'gray.800'} backgroundColor={Colors.primary['300']} />
                        </MenuButton>
                        <MenuList>
                            {subscriptionData && showQuotaBar() && (
                                <Show below={'md'}>
                                    <MenuItem borderBottom={`1px solid ${Colors.divider}`} pb={3}>
                                        <QuotaBar
                                            max={getPlanCredits(subscriptionData.plan)}
                                            current={
                                                getPlanCredits(subscriptionData.plan) - subscriptionData.usedCredits
                                            }
                                            openUpgradeDialog={handleEmptyQuotaUpgradeClicked}
                                        />
                                    </MenuItem>
                                </Show>
                            )}
                            {subscriptionData?.plan !== 400 && (
                                <MenuItem onClick={() => handleCheckoutClicked()}>{t('header.upgradePlan')}</MenuItem>
                            )}
                            {subscriptionData?.customerId && (
                                <MenuItem onClick={() => handleBillingItemClicked()}>
                                    {t('header.menu.billing')}
                                </MenuItem>
                            )}
                            <MenuItem onClick={() => handleSupportClicked()}>{t('header.menu.support')}</MenuItem>
                            <MenuItem onClick={() => supabaseLogout(history)}>{t('header.menu.logout')}</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </Box>
        </Flex>
    )
}

export default Header
