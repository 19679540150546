import * as React from 'react'
import { useEffect } from 'react'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { supabase } from '../../../data/services/supabaseService'
import { Box, Card, Flex, Link, Text } from '@chakra-ui/react'
import Colors from '../../theme/Colors'
import { useHistory } from 'react-router-dom'

import enAuthLocale from '../../../services/localization/locales/en/auth.json'
import { useTranslation } from 'react-i18next'
import { use100vh } from 'react-div-100vh'
import mixpanel from 'mixpanel-browser'
import { AARRRTrackEvents, AuthTrackEvents } from '../../../utils/trackEvents'

const AuthScreen = () => {
    const { t } = useTranslation()
    const height = use100vh()
    const history = useHistory()

    useEffect(() => {
        mixpanel.track(AARRRTrackEvents.ACQ_SIGNUP_PAGE_VISIT)
        const listener = supabase.auth.onAuthStateChange((event, session) => {
            if (event == 'SIGNED_IN') {
                if (session) {
                    mixpanel.identify(session.user.id)
                    mixpanel.track(AARRRTrackEvents.ACQ_SIGNUP_DONE)
                    history.push('/')
                }
            }
        })

        return () => {
            listener.data.subscription.unsubscribe()
        }
    }, [])

    const goToTOCPage = (event: any) => {
        event.preventDefault()
        mixpanel.track(AuthTrackEvents.TOC_LINK_CLICKED)
        window.open('https://scraptio.com/policies/terms-and-conditions', '_blank')
    }

    const goToPrivacyPage = (event: any) => {
        event.preventDefault()
        mixpanel.track(AuthTrackEvents.PRIVACY_LINK_CLICKED)
        window.open('https://scraptio.com/policies/privacy-policy', '_blank')
    }

    return (
        <Flex h={height || '100vh'}>
            <Box
                flex={1}
                bgGradient={`linear(to-br, ${Colors.backgroundGradient.left} 0%, ${Colors.backgroundGradient.right} 100%)`}
                p={2}
                overflowY={'hidden'}>
                <Flex
                    h={'100%'}
                    maxW={{ xl: '1200px' }}
                    m="0 auto"
                    p={2}
                    maxH={'100%'}
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}>
                    <Box overflowY={'auto'} className={'no-scrollbar '}>
                        <Card
                            bg={'white'}
                            w={'100%'}
                            minW={'300px'}
                            maxW={'400px'}
                            m={'0 auto'}
                            p={8}
                            borderRadius={12}>
                            <Flex justifyContent={'center'}>
                                <Link href={'https://scraptio.com'} isExternal>
                                    <img
                                        alt="Scraptio"
                                        src="https://scraptio.s3.eu-west-3.amazonaws.com/scraptio-icon-name.svg"
                                        style={{ maxWidth: 200 }}
                                    />
                                </Link>
                            </Flex>
                            <Text my={2} textAlign={'center'}>
                                {t('login.subtitle')}
                            </Text>
                            <Auth
                                supabaseClient={supabase}
                                redirectTo={'https://app.scraptio.com'}
                                socialLayout={'horizontal'}
                                localization={{
                                    variables: enAuthLocale,
                                }}
                                appearance={{
                                    theme: ThemeSupa,
                                    variables: {
                                        default: {
                                            colors: {
                                                brand: Colors.primary['300'],
                                                brandAccent: Colors.primary['500'],
                                                brandButtonText: Colors.text,
                                            },
                                        },
                                    },
                                }}
                                providers={['google']}
                            />
                        </Card>
                        <Text
                            fontSize="sm"
                            color={'gray.500'}
                            textAlign={'center'}
                            w={'100%'}
                            minW={'300px'}
                            maxW={'400px'}
                            py={4}
                            px={4}
                            m={'0 auto'}>
                            {t('auth.toc.pre') + ' '}
                            <a href={'https://scraptio.com/policies/terms-and-conditions'} onClick={goToTOCPage}>
                                <b>{t('auth.toc.toc-label')}</b>
                            </a>
                            {' ' + t('auth.toc.and') + ' '}
                            <a href={'https://scraptio.com/policies/privacy-policy'} onClick={goToPrivacyPage}>
                                <b>{t('auth.toc.privacy-label')}</b>
                            </a>
                            {t('auth.toc.post')}
                        </Text>
                    </Box>
                </Flex>
            </Box>
        </Flex>
    )
}

export default AuthScreen
