// ACTION TYPES
import { Execution } from '../../models/Execution'

export const SET_SCRAPE_RESULT_ACTION = 'SET_SCRAPE_RESULT_ACTION'
export const SET_SCRAPE_ERROR_ACTION = 'SET_SCRAPE_ERROR_ACTION'
export const CLEAN_SCRAPE_ACTION = 'CLEAN_SCRAPE_ERROR_ACTION'

export interface SetScrapeResultAction {
    type: typeof SET_SCRAPE_RESULT_ACTION
    payload: {
        scrapeResult: Execution
    }
}

export interface SetScrapeErrorAction {
    type: typeof SET_SCRAPE_ERROR_ACTION
    payload: {
        error: string
    }
}

export interface CleanScrapeAction {
    type: typeof CLEAN_SCRAPE_ACTION
}

export type ScraperActionReduxTypes = SetScrapeResultAction | SetScrapeErrorAction | CleanScrapeAction
