// ACTION TYPES
import { ApiKey } from '../../models/ApiKey'

export const SET_API_KEYS_ACTION = 'SET_API_KEYS_ACTION'
export const ADD_NEW_API_KEY_ACTION = 'ADD_NEW_API_KEY_ACTION'
export const SET_API_KEYS_ERROR_ACTION = 'SET_API_KEYS_ERROR_ACTION'
export const ADD_NEW_API_KEY_ERROR_ACTION = 'ADD_NEW_API_KEY_ERROR_ACTION'
export const CLEAR_NEW_API_KEY_ACTION = 'CLEAR_NEW_API_KEY_ACTION'

export interface SetApiKeysDataAction {
    type: typeof SET_API_KEYS_ACTION
    payload: {
        apiKeys: ApiKey[]
    }
}

export interface AddNewApiKeyAction {
    type: typeof ADD_NEW_API_KEY_ACTION
    payload: {
        apiKey: ApiKey
    }
}

export interface SetApiKeysErrorAction {
    type: typeof SET_API_KEYS_ERROR_ACTION
    payload: {
        error: string
    }
}

export interface AddNewApiKeyErrorAction {
    type: typeof ADD_NEW_API_KEY_ERROR_ACTION
    payload: {
        error: string
    }
}

export interface ClearNewApiKeyAction {
    type: typeof CLEAR_NEW_API_KEY_ACTION
}

export type ApiKeysActionReduxTypes =
    | SetApiKeysDataAction
    | AddNewApiKeyAction
    | SetApiKeysErrorAction
    | AddNewApiKeyErrorAction
    | ClearNewApiKeyAction
