import { Dispatch } from 'redux'
import {
    CLEAN_SCRAPE_ACTION,
    CleanScrapeAction,
    SET_SCRAPE_ERROR_ACTION,
    SET_SCRAPE_RESULT_ACTION,
    SetScrapeErrorAction,
    SetScrapeResultAction,
} from './scraperActions.redux'
import { scrapeRepository } from '../../../data/repositories/scrapeRepository'
import { executionActions } from '../execution/executionActions'
import { ExecutionMode } from '../../models/ExecutionMode'

export type ScraperActionsType = {
    scrapeWebsite: (websiteUrl: string, filters: string[], mode: ExecutionMode, matchAllFilters?: boolean) => void
    cleanScrapeData: () => void
}

export const scraperActions = (dispatch: Dispatch): ScraperActionsType => {
    const scrapeWebsite: ScraperActionsType['scrapeWebsite'] = async (
        websiteUrl,
        filters,
        mode,
        matchAllFilters = false,
    ) => {
        dispatch<SetScrapeErrorAction>({
            type: SET_SCRAPE_ERROR_ACTION,
            payload: {
                error: '',
            },
        })

        try {
            const scrapeResult = await scrapeRepository().scrapeWebsite(websiteUrl, filters, mode, matchAllFilters)
            dispatch<SetScrapeResultAction>({
                type: SET_SCRAPE_RESULT_ACTION,
                payload: {
                    scrapeResult,
                },
            })

            executionActions(dispatch).getExecutions(null)
        } catch (e: any) {
            console.error(e)
            dispatch<SetScrapeErrorAction>({
                type: SET_SCRAPE_ERROR_ACTION,
                payload: {
                    error: e.message,
                },
            })
        }
    }

    const cleanScrapeData: ScraperActionsType['cleanScrapeData'] = () => {
        dispatch<CleanScrapeAction>({
            type: CLEAN_SCRAPE_ACTION,
        })
    }

    return {
        scrapeWebsite,
        cleanScrapeData,
    }
}
