import React, { FunctionComponent, useState } from 'react'
import { Box, Flex, useDisclosure } from '@chakra-ui/react'
import Header from '../components/header/Header'
import { use100vh } from 'react-div-100vh'
import Colors from '../theme/Colors'
import LoadingDialog from '../components/LoadingDialog'
import UpgradeDialog from '../components/UpgradeDialog'

interface Props {
    showHeader?: boolean
    children: JSX.Element
}

export const MainLayout: FunctionComponent<Props> = ({ showHeader = true, children }) => {
    const height = use100vh()
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <Flex h={height || '100vh'}>
            <Box
                flex={1}
                bgGradient={`linear(to-br, ${Colors.backgroundGradient.left} 0%, ${Colors.backgroundGradient.right} 100%)`}
                p={[2, 4, 8]}
                overflowY={'hidden'}>
                <Flex direction="column" h={'100%'} maxW={{ xl: '1200px' }} m="0 auto" p={4}>
                    {showHeader && <Header openUpgradeDialog={onOpen} />}
                    <Flex direction="row" overflowY={'auto'} className={'no-scrollbar'}>
                        <Flex flex={2}>{children}</Flex>
                    </Flex>
                </Flex>
            </Box>
            <LoadingDialog />
            <UpgradeDialog isOpen={isOpen} onClose={onClose} />
        </Flex>
    )
}
