// ACTION TYPES
import { Execution } from '../../models/Execution'

export const SET_EXECUTIONS_ACTION = 'SET_EXECUTIONS_ACTION'
export const SET_EXECUTIONS_ERROR_ACTION = 'SET_EXECUTIONS_ERROR_ACTION'

export interface SetExecutionDataAction {
    type: typeof SET_EXECUTIONS_ACTION
    payload: {
        executions: Execution[]
        offset: number
        reload?: boolean
    }
}

export interface SetExecutionErrorAction {
    type: typeof SET_EXECUTIONS_ERROR_ACTION
    payload: {
        error: string
    }
}

export type ExecutionActionReduxTypes = SetExecutionDataAction | SetExecutionErrorAction
