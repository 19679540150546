export const escapedNewLineToLineBreakTag = (text: string) => {
    return text.replace(/\\n/g, '<br/>')
}

export const getUserLanguage = (i18nLang: string): string => {
    return i18nLang.includes('es') ? 'es' : 'en'
}

export const cleanUrl = (url: string): string => {
    return url.replace(/^(https?:\/\/)?(www\.)?/i, '').replace(/\/$/, '')
}
