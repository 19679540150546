import { initialState } from '../initialState'
import { Execution } from '../../models/Execution'
import {
    CLEAN_SCRAPE_ACTION,
    ScraperActionReduxTypes,
    SET_SCRAPE_ERROR_ACTION,
    SET_SCRAPE_RESULT_ACTION,
} from '../../actions/scraper/scraperActions.redux'

export interface ScraperReducerType {
    scrapeResult: Execution | null
    error: string
}

export const scraperReducer = (
    state: ScraperReducerType = initialState.scraperReducer,
    action: ScraperActionReduxTypes,
): ScraperReducerType => {
    switch (action.type) {
        case SET_SCRAPE_RESULT_ACTION:
            return {
                ...state,
                scrapeResult: action.payload.scrapeResult,
            }
        case SET_SCRAPE_ERROR_ACTION:
            return {
                ...state,
                error: action.payload.error,
            }
        case CLEAN_SCRAPE_ACTION:
            return {
                ...initialState.scraperReducer,
            }
        default:
            return { ...state }
    }
}
