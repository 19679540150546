import { Dispatch } from 'redux'
import {
    SET_EXECUTIONS_ACTION,
    SET_EXECUTIONS_ERROR_ACTION,
    SetExecutionDataAction,
    SetExecutionErrorAction,
} from './executionActions.redux'
import { executionRepository } from '../../../data/repositories/executionRepository'

export type ExecutionActionsType = {
    getExecutions: (offset?: number | null) => void
}

export const executionActions = (dispatch: Dispatch): ExecutionActionsType => {
    const getExecutions: ExecutionActionsType['getExecutions'] = async (offset = null) => {
        dispatch<SetExecutionErrorAction>({
            type: SET_EXECUTIONS_ERROR_ACTION,
            payload: {
                error: '',
            },
        })

        try {
            const executions = await executionRepository().getExecutions(offset)
            dispatch<SetExecutionDataAction>({
                type: SET_EXECUTIONS_ACTION,
                payload: {
                    executions,
                    offset: offset ? offset + executions.length : executions.length,
                    reload: offset === null,
                },
            })
        } catch (e: any) {
            console.error(e)
            dispatch<SetExecutionErrorAction>({
                type: SET_EXECUTIONS_ERROR_ACTION,
                payload: {
                    error: e.message,
                },
            })
        }
    }

    return {
        getExecutions,
    }
}
