import { initialState } from '../initialState'
import {
    SET_LOADING_STRIPE,
    SubscriptionActionReduxTypes,
    UPDATE_SUBSCRIPTION_DATA_ACTION,
} from '../../actions/subscription/subscriptionActions.redux'
import { Subscription } from '../../models/Subscription'
import { calculateRemainingCredits } from '../../actions/subscription/subscriptionActions'

export interface SubscriptionReducerType {
    subscriptionData: Subscription | null
    creditsAvailable: boolean
    remainingCredits: number
    isLoadingStripe: boolean
}

export const subscriptionReducer = (
    state: SubscriptionReducerType = initialState.subscriptionReducer,
    action: SubscriptionActionReduxTypes,
): SubscriptionReducerType => {
    switch (action.type) {
        case UPDATE_SUBSCRIPTION_DATA_ACTION:
            return {
                ...state,
                subscriptionData: action.payload.subscription,
                creditsAvailable:
                    calculateRemainingCredits(
                        action.payload.subscription.plan,
                        action.payload.subscription.usedCredits,
                    ) > 0,
                remainingCredits: calculateRemainingCredits(
                    action.payload.subscription.plan,
                    action.payload.subscription.usedCredits,
                ),
            }
        case SET_LOADING_STRIPE:
            return {
                ...state,
                isLoadingStripe: action.payload.isLoadingStripe,
            }
        default:
            return { ...state }
    }
}
