import { Dispatch } from 'redux'
import { subscriptionRepository } from '../../../data/repositories/subscriptionRepository'
import {
    SET_LOADING_STRIPE,
    SetLoadingStripeAction,
    UPDATE_SUBSCRIPTION_DATA_ACTION,
    UpdateSubscriptionDataAction,
} from './subscriptionActions.redux'
import { StateType } from '../../state/types'
import { PaymentMode, stripeRepository } from '../../../data/repositories/stripeRepository'

export type SubscriptionActionsType = {
    getSubscriptionData: () => void
    addUsedCredits: (credits?: number) => void
    getCheckoutSession: (productId: string, store: any) => Promise<string>
    getCustomerPortalSession: (customerId: string) => Promise<string>
}

// Plans: 1 - Pro/Basic plans // 2 - Ultra plans // 300 - Ultra 50K plans // 400 - Ultra 100K plans
// Lifetime plans: 1000 - Pro Lifetime // 2000 - Ultra Lifetime
export const calculateRemainingCredits = (plan: number, usedCredits: number) => {
    const planCredits = getPlanCredits(plan)
    return planCredits > 0 ? planCredits - usedCredits : 1000
}

export const getPlanCredits = (plan: number) => {
    switch (plan) {
        case 0:
            return 30
        case 1:
            return 500
        case 2:
            return 10000
        case 300:
            return 50000
        case 400:
            return 100000
        case 1000:
            return 500
        case 2000:
            return 10000
        default:
            return 0
    }
}

// Production mode
export const PRO_PRODUCT_ID = process.env.REACT_APP_STRIPE_PLAN_PRO_ID_V2 || ''
export const ULTRA_PRODUCT_ID = process.env.REACT_APP_STRIPE_PLAN_ULTRA_ID_V2 || ''
export const ULTRA_PRODUCT_50K_ID = process.env.REACT_APP_STRIPE_PLAN_ULTRA_50K_ID || ''
export const ULTRA_PRODUCT_100K_ID = process.env.REACT_APP_STRIPE_PLAN_ULTRA_100K_ID || ''
export const PRO_LIFETIME_PRODUCT_ID = process.env.REACT_APP_STRIPE_PLAN_PRO_LIFETIME_ID_V1 || ''
export const ULTRA_LIFETIME_PRODUCT_ID = process.env.REACT_APP_STRIPE_PLAN_ULTRA_LIFETIME_ID_V1 || ''

export const subscriptionActions = (dispatch: Dispatch): SubscriptionActionsType => {
    const getSubscriptionData: SubscriptionActionsType['getSubscriptionData'] = async () => {
        try {
            let subscriptionData = await subscriptionRepository().getSubscription()
            if (!subscriptionData) {
                // Sleep for 5 seconds
                await new Promise((resolve) => setTimeout(resolve, 5000))
                subscriptionData = await subscriptionRepository().getSubscription()
            }

            if (subscriptionData) {
                dispatch<UpdateSubscriptionDataAction>({
                    type: UPDATE_SUBSCRIPTION_DATA_ACTION,
                    payload: {
                        subscription: subscriptionData,
                    },
                })
            }
        } catch (e: any) {
            console.error(e)
        }
    }

    const addUsedCredit: SubscriptionActionsType['addUsedCredits'] = async (credits = 1) => {
        try {
            const subscriptionData = await subscriptionRepository().addUsedCredit(credits)

            dispatch<UpdateSubscriptionDataAction>({
                type: UPDATE_SUBSCRIPTION_DATA_ACTION,
                payload: {
                    subscription: subscriptionData,
                },
            })
        } catch (e: any) {
            console.error(e)
        }
    }

    const getCheckoutSession: SubscriptionActionsType['getCheckoutSession'] = async (productId, store) => {
        dispatch<SetLoadingStripeAction>({ type: SET_LOADING_STRIPE, payload: { isLoadingStripe: true } })
        const subscriptionData = await (store.getState() as StateType).subscriptionReducer.subscriptionData

        let paymentMode = PaymentMode.SUBSCRIPTION
        if (productId === PRO_LIFETIME_PRODUCT_ID || productId === ULTRA_LIFETIME_PRODUCT_ID) {
            paymentMode = PaymentMode.ONE_TIME
        }

        const result = await stripeRepository().getCheckoutSession(
            productId,
            paymentMode,
            subscriptionData?.customerId || undefined,
        )

        return result
    }

    const getCustomerPortalSession: SubscriptionActionsType['getCustomerPortalSession'] = async (customerId) => {
        dispatch<SetLoadingStripeAction>({ type: SET_LOADING_STRIPE, payload: { isLoadingStripe: true } })
        const result = await stripeRepository().getCustomerPortalSession(customerId)

        return result
    }

    return {
        getSubscriptionData,
        addUsedCredits: addUsedCredit,
        getCheckoutSession,
        getCustomerPortalSession,
    }
}
