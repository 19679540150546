import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import {
    Button,
    FormControl,
    FormLabel,
    HStack,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useToast,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import Colors from '../../theme/Colors'
import { apiKeysActions } from '../../../domain/actions/apiKeys/apiKeysActions'
import { useDispatch, useSelector } from 'react-redux'
import { StateType } from '../../../domain/state/types'
import mixpanel from 'mixpanel-browser'
import { ApiKeyTrackEvents } from '../../../utils/trackEvents'

interface Props {
    isOpen: boolean
    onClose: () => void
}

const NeyKeyDialog: FunctionComponent<Props> = ({ isOpen, onClose }) => {
    const initialRef = useRef(null)
    const dispatch = useDispatch()
    const toast = useToast()
    const { t } = useTranslation()

    const [creatingKey, setCreatingKey] = useState(false)
    const [keyCreated, setKeyCreated] = useState(false)
    const [title, setTitle] = useState('')
    const [titleError, setTitleError] = useState(false)

    const { newApiKey, newApiKeyError } = useSelector((state: StateType) => {
        return state.apiKeysReducer
    })

    useEffect(() => {
        if (newApiKey) {
            setCreatingKey(false)
            setKeyCreated(true)
        }
    }, [newApiKey])

    useEffect(() => {
        if (newApiKeyError) {
            toast({
                title: t('error.cannotAddApiKey.title'),
                description: t('error.cannotAddApiKey.description'),
                status: 'error',
                position: 'bottom',
                duration: 3000,
                isClosable: true,
            })
        }
    }, [newApiKeyError])

    const handleOnCreateButtonClicked = () => {
        if (title === '') {
            setTitleError(true)
            return
        }

        setCreatingKey(true)
        apiKeysActions(dispatch).createNewApiKey(title)
    }

    const handleOnClose = () => {
        setCreatingKey(false)
        setKeyCreated(false)
        setTitle('')
        setTitleError(false)
        apiKeysActions(dispatch).clearNewApiKey()
        onClose()
    }

    const handleCopyApiKey = () => {
        if (newApiKey) {
            navigator.clipboard.writeText(newApiKey.apiKey)
            mixpanel.track(ApiKeyTrackEvents.APIKEY_COPY_CLICKED)
            toast({
                title: t('successFeedback.copy.title'),
                description: t('successFeedback.copy.description'),
                status: 'success',
                position: 'bottom',
                duration: 3000,
                isClosable: true,
            })
        }
    }

    const renderCreateKeyForm = () => {
        return (
            <>
                <ModalHeader>{t('newApiKeyDialog.firstStep.title')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl>
                        <FormLabel>{t('newApiKeyDialog.firstStep.titleLabel')}</FormLabel>
                        <Input
                            ref={initialRef}
                            value={title}
                            onChange={(event) => {
                                setTitle(event.target.value)
                                setTitleError(false)
                            }}
                            placeholder="API key title"
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    handleOnCreateButtonClicked()
                                }
                            }}
                            isInvalid={titleError}
                            errorBorderColor="red.300"
                            focusBorderColor="brand.500"
                        />
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={handleOnClose} mr={3}>
                        {t('common.cancel')}
                    </Button>
                    <Button colorScheme="brand" isLoading={creatingKey} onClick={handleOnCreateButtonClicked}>
                        {t('common.create')}
                    </Button>
                </ModalFooter>
            </>
        )
    }

    const renderKeyCreated = () => {
        return (
            <>
                <ModalHeader>{t('newApiKeyDialog.secondStep.title')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <Text color={'orange.500'}>{t('newApiKeyDialog.secondStep.description')}</Text>
                    <Text color={Colors.textSecondary} fontWeight={'bold'} fontSize={'md'} mt={'16px'}>
                        {t('newApiKeyDialog.secondStep.titleLabel')}
                    </Text>
                    <Text color={Colors.text} fontSize={'lg'} fontWeight={'bold'} mt={'4px'}>
                        {newApiKey?.title}
                    </Text>
                    <HStack mt={'16px'} justifyContent={'space-between'}>
                        <Text color={Colors.textSecondary} fontWeight={'bold'} fontSize={'md'}>
                            {t('newApiKeyDialog.secondStep.apiKeyLabel')}
                        </Text>
                        <Text
                            color={Colors.primary[900]}
                            fontSize={'md'}
                            cursor={'pointer'}
                            onClick={handleCopyApiKey}
                            transition={'color'}
                            transitionDuration={'0.2s'}
                            _hover={{
                                color: Colors.primary['600'],
                            }}>
                            {t('common.copy')}
                        </Text>
                    </HStack>
                    <Text color={Colors.text} fontSize={'lg'} fontWeight={'bold'} mt={'4px'}>
                        {newApiKey?.apiKey}
                    </Text>
                </ModalBody>

                <ModalFooter>
                    <Button isLoading={creatingKey} onClick={handleOnClose}>
                        {t('common.continue')}
                    </Button>
                </ModalFooter>
            </>
        )
    }

    return (
        <Modal initialFocusRef={initialRef} size={'xl'} isOpen={isOpen} onClose={handleOnClose}>
            <ModalOverlay />
            <ModalContent marginX={4}>{keyCreated ? renderKeyCreated() : renderCreateKeyForm()}</ModalContent>
        </Modal>
    )
}

export default NeyKeyDialog
