import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import {
    Button,
    Flex,
    FormControl,
    FormHelperText,
    FormLabel,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Switch,
    Tag,
    TagCloseButton,
    TagLabel,
    useToast,
} from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { scraperActions } from '../../domain/actions/scraper/scraperActions'
import { StateType } from '../../domain/state/types'
import ExecutionDetailDialogContent from './ExecutionHistorySection/ExecutionDetailDialogContent'
import mixpanel from 'mixpanel-browser'
import { ScrapeTrackEvents } from '../../utils/trackEvents'
import { ExecutionMode } from '../../domain/models/ExecutionMode'

interface Props {
    isOpen: boolean
    onClose: () => void
}

const NeyScrapeDialog: FunctionComponent<Props> = ({ isOpen, onClose }) => {
    const initialRef = useRef(null)
    const dispatch = useDispatch()
    const toast = useToast()
    const { t } = useTranslation()

    const [scrapingWeb, setScrapingWeb] = useState(false)
    const [webScrapped, setWebScrapped] = useState(false)
    const [website, setWebsite] = useState('')
    const [tagsField, setTagsField] = useState('')
    const [tags, setTags] = useState<string[]>([])
    const [modeSelection, setModeSelection] = useState(ExecutionMode.TEXTS)
    const [matchAllFilters, setMatchAllFilters] = useState(false)
    const [websiteError, setWebsiteError] = useState(false)

    const { scrapeResult, error: scrapeError } = useSelector((state: StateType) => {
        return state.scraperReducer
    })

    useEffect(() => {
        if (scrapeResult) {
            setScrapingWeb(false)
            setWebScrapped(true)
        }
    }, [scrapeResult])

    useEffect(() => {
        if (scrapeError) {
            setScrapingWeb(false)
            toast({
                title: t('error.cannotScrapeWebsite.title'),
                description: t('error.cannotScrapeWebsite.description'),
                status: 'error',
                position: 'bottom',
                duration: 3000,
                isClosable: true,
            })
        }
    }, [scrapeError])

    const validateWebsiteUrl = (websiteUrl: string): boolean => {
        // Regular expression pattern for matching URLs
        const urlPattern = /^(?:https?:\/\/)?(?:www\.)?[\w\-.+]+\.[\w\-.+]+(?:\/.*)?$/

        // Return true if the input is a non-empty string and matches the URL pattern
        return websiteUrl.length > 0 && urlPattern.test(websiteUrl)
    }

    const handleOnScrapeButtonClicked = () => {
        if (website === '') {
            setWebsiteError(true)
            mixpanel.track(ScrapeTrackEvents.SCRAPE_VALIDATION_INCORRECT, {
                error: 'Website is empty',
            })
            return
        }
        setScrapingWeb(true)

        let formattedUrl = website
        if (!/^https?:\/\//i.test(website)) {
            formattedUrl = `https://${website}`
        }

        if (!validateWebsiteUrl(formattedUrl)) {
            setWebsiteError(true)
            setScrapingWeb(false)
            mixpanel.track(ScrapeTrackEvents.SCRAPE_VALIDATION_INCORRECT, {
                error: 'Text is not a valid url',
                url: formattedUrl,
            })
            return
        }

        mixpanel.track(ScrapeTrackEvents.SCRAPE_VALIDATION_CORRECT)
        scraperActions(dispatch).scrapeWebsite(formattedUrl, tags, modeSelection, matchAllFilters)
    }

    const addTag = () => {
        if (tagsField === '') {
            return
        }

        tags.push(tagsField)
        setTagsField('')
    }

    const removeTag = (tag: string) => {
        const index = tags.indexOf(tag)
        if (index > -1) {
            tags.splice(index, 1)
        }
        setTags([...tags])
    }

    const handleOnClose = () => {
        setScrapingWeb(false)
        setWebScrapped(false)
        setWebsite('')
        setTagsField('')
        setTags([])
        setMatchAllFilters(false)
        setWebsiteError(false)
        setModeSelection(ExecutionMode.TEXTS)
        scraperActions(dispatch).cleanScrapeData()
        onClose()
    }

    const handleOnSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setModeSelection(event.target.value as ExecutionMode)
    }

    const renderFirstStep = () => {
        return (
            <>
                <ModalHeader>{t('scrapeDialog.firstStep.title')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl isRequired>
                        <FormLabel>{t('scrapeDialog.firstStep.websiteLabel')}</FormLabel>
                        <Input
                            ref={initialRef}
                            value={website}
                            onChange={(event) => {
                                setWebsite(event.target.value)
                                setWebsiteError(false)
                            }}
                            placeholder="Website url..."
                            isInvalid={websiteError}
                            errorBorderColor="red.300"
                            focusBorderColor="brand.500"
                        />
                    </FormControl>
                    <FormControl mt={'16px'} isRequired>
                        <FormLabel>{t('scrapeDialog.firstStep.typeLabel')}</FormLabel>
                        <Select value={modeSelection} focusBorderColor="brand.500" onChange={handleOnSelectChange}>
                            <option value={ExecutionMode.TEXTS}>{t('scrapeDialog.firstStep.typeTexts')}</option>
                            <option value={ExecutionMode.TEXTS_INDIVIDUAL}>
                                {t('scrapeDialog.firstStep.typeTextSplit')}
                            </option>
                            <option value={ExecutionMode.LINKS_ABSOLUTE}>
                                {t('scrapeDialog.firstStep.typeLinksAbsolute')}
                            </option>
                            <option value={ExecutionMode.LINKS_RELATIVE}>
                                {t('scrapeDialog.firstStep.typeLinksRelative')}
                            </option>
                            <option value={ExecutionMode.EMAILS}>{t('scrapeDialog.firstStep.typeEmails')}</option>
                        </Select>
                    </FormControl>
                    <FormControl mt={'16px'}>
                        <FormLabel>{t('scrapeDialog.firstStep.tagsLabel')}</FormLabel>
                        <InputGroup>
                            <Input
                                value={tagsField}
                                placeholder="CSS id, class or HTML tag..."
                                onChange={(event) => {
                                    setTagsField(event.target.value)
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        addTag()
                                    }
                                }}
                                focusBorderColor="brand.500"
                            />
                            <InputRightElement>
                                <IconButton
                                    colorScheme="brand"
                                    aria-label="Add tag"
                                    icon={<AddIcon />}
                                    size={'xs'}
                                    onClick={addTag}
                                />
                            </InputRightElement>
                        </InputGroup>
                        <Flex hidden={tags.length === 0} mt={'12px'} wrap={'wrap'}>
                            {tags.map((tagLabel) => (
                                <Tag
                                    size={'md'}
                                    key={tagLabel}
                                    variant="outline"
                                    colorScheme="brandButton"
                                    mr={2}
                                    mb={'8px'}>
                                    <TagLabel>{tagLabel}</TagLabel>
                                    <TagCloseButton onClick={() => removeTag(tagLabel)} />
                                </Tag>
                            ))}
                        </Flex>
                        <FormHelperText mt={'8px'}>
                            {t('scrapeDialog.firstStep.tagsHelp')}
                            <Link
                                href={
                                    'https://scraptio.notion.site/scraptio/Scraptio-Docs-6414064fb16342098de4e251cf9f89f5'
                                }
                                isExternal
                                color={'brand.900'}>
                                {t('scrapeDialog.firstStep.readMoreHelp')}
                            </Link>
                        </FormHelperText>
                    </FormControl>
                    <FormControl
                        hidden={tags.length === 0}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mt="16px">
                        <FormLabel htmlFor="match-all-filters-label" mb="0">
                            {t('scrapeDialog.firstStep.onlyMatchAllLabel')}
                        </FormLabel>
                        <Switch
                            id="match-all-filters"
                            colorScheme="brand"
                            size="lg"
                            isChecked={matchAllFilters}
                            onChange={(event) => setMatchAllFilters(event.target.checked)}
                        />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleOnClose} mr={3}>
                        {t('common.cancel')}
                    </Button>
                    <Button colorScheme="brand" isLoading={scrapingWeb} onClick={handleOnScrapeButtonClicked}>
                        {t('scrapeDialog.firstStep.action')}
                    </Button>
                </ModalFooter>
            </>
        )
    }

    const renderSecondStep = () => {
        return (
            <ExecutionDetailDialogContent
                websiteUrl={scrapeResult?.websiteUrl || ''}
                content={scrapeResult?.resultMessage || ''}
                onClose={handleOnClose}
                mode={scrapeResult?.mode || ExecutionMode.TEXTS}
            />
        )
    }

    return (
        <Modal initialFocusRef={initialRef} size={'xl'} isOpen={isOpen} onClose={handleOnClose}>
            <ModalOverlay />
            <ModalContent marginX={4}>{webScrapped ? renderSecondStep() : renderFirstStep()}</ModalContent>
        </Modal>
    )
}

export default NeyScrapeDialog
