import { ExecutionEntity } from '../models/ExecutionEntity'
import { Execution } from '../../domain/models/Execution'
import { ExecutionType } from '../../domain/models/ExecutionType'
import moment from 'moment'
import { ExecutionMode } from '../../domain/models/ExecutionMode'

export const executionParser = {
    toDomain: (entity: ExecutionEntity): Execution => {
        return {
            id: entity.id,
            websiteUrl: entity.website_url,
            resultCode: entity.result_code,
            resultMessage: entity.result_message,
            origin: parseOrigin(entity.origin),
            createdAt: moment(entity.created_at),
            mode: parseMode(entity.mode),
        }
    },
}

const parseOrigin = (origin: number): ExecutionType => {
    switch (origin) {
        case 1:
            return ExecutionType.API
        case 2:
            return ExecutionType.MAKE
        case 3:
            return ExecutionType.ZAPIER
        default:
            return ExecutionType.MANUAL
    }
}

const parseMode = (mode: string): ExecutionMode => {
    switch (mode) {
        case 'texts':
            return ExecutionMode.TEXTS
        case 'texts-individual':
            return ExecutionMode.TEXTS_INDIVIDUAL
        case 'links-absolute':
            return ExecutionMode.LINKS_ABSOLUTE
        case 'links-relative':
            return ExecutionMode.LINKS_RELATIVE
        case 'emails':
            return ExecutionMode.EMAILS
        default:
            return ExecutionMode.TEXTS
    }
}
