import React, { FunctionComponent } from 'react'
import { Box, Flex, HStack, IconButton, Text, Tooltip } from '@chakra-ui/react'
import Colors from '../../theme/Colors'
import { AiOutlineDelete } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { Moment } from 'moment'
import useWindowDimensions from '../../../utils/useWindowDimensions'

interface Props {
    name: string
    keyHint: string
    createdAt: Moment
    expiresAt: Moment | null
    onDeleteClick: () => void
}

const ApiKeyItem: FunctionComponent<Props> = ({ name, keyHint, createdAt, expiresAt, onDeleteClick }) => {
    const { t } = useTranslation()
    const { width } = useWindowDimensions()

    return (
        <Box
            backgroundColor={Colors.whiteOpacity}
            borderRadius={12}
            my={'12px'}
            p={4}
            transition={'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform'}
            transitionDuration={'0.2s'}
            border={`1px solid #FFFFFF00`}>
            <Flex justifyContent={'space-between'} alignItems={'center'}>
                <Box>
                    <HStack>
                        <Text
                            fontSize={'lg'}
                            fontWeight={'bold'}
                            color={Colors.text}
                            noOfLines={1}
                            w={Math.min(width - 180, 1000)}>
                            {name}
                        </Text>
                        {/*<Text fontSize={'md'} color={Colors.textSecondary}>*/}
                        {/*    {`${keyHint}`}*/}
                        {/*</Text>*/}
                    </HStack>
                    <Text fontSize={'sm'} color={Colors.textSecondary}>
                        {expiresAt === null
                            ? `${t('apiKeyItem.createdAt')} ${createdAt.format('DD/MM/YYYY - HH:mm')}`
                            : `${t('apiKeyItem.createdAt')} ${createdAt.format('DD/MM/YYYY - HH:mm')} / ${t(
                                  'apiKeyItem.expiresAt',
                              )} ${expiresAt.format('DD/MM/YYYY')}`}
                    </Text>
                </Box>
                <Box>
                    <Tooltip label={t('apiKeyItem.deleteTooltip')} placement={'left'} openDelay={500}>
                        <IconButton
                            color={Colors.text}
                            colorScheme="ctas"
                            aria-label={t('apiKeyItem.deleteTooltip')}
                            icon={<AiOutlineDelete />}
                            size={'md'}
                            onClick={(event) => onDeleteClick()}
                        />
                    </Tooltip>
                </Box>
            </Flex>
        </Box>
    )
}

export default ApiKeyItem
