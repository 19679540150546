import { initialState } from '../initialState'
import { ApiKey } from '../../models/ApiKey'
import {
    ADD_NEW_API_KEY_ACTION,
    ADD_NEW_API_KEY_ERROR_ACTION,
    ApiKeysActionReduxTypes,
    CLEAR_NEW_API_KEY_ACTION,
    SET_API_KEYS_ACTION,
    SET_API_KEYS_ERROR_ACTION,
} from '../../actions/apiKeys/apiKeysActions.redux'

export interface ApiKeysReducerType {
    apiKeys: ApiKey[] | null
    error: string
    newApiKey: ApiKey | null
    newApiKeyError: string
}

export const apiKeysReducer = (
    state: ApiKeysReducerType = initialState.apiKeysReducer,
    action: ApiKeysActionReduxTypes,
): ApiKeysReducerType => {
    switch (action.type) {
        case SET_API_KEYS_ACTION:
            return {
                ...state,
                apiKeys: action.payload.apiKeys,
            }
        case SET_API_KEYS_ERROR_ACTION:
            return {
                ...state,
                error: action.payload.error,
            }
        case ADD_NEW_API_KEY_ACTION:
            return {
                ...state,
                newApiKey: action.payload.apiKey,
            }
        case ADD_NEW_API_KEY_ERROR_ACTION:
            return {
                ...state,
                newApiKeyError: action.payload.error,
            }
        case CLEAR_NEW_API_KEY_ACTION:
            return {
                ...state,
                newApiKey: null,
                newApiKeyError: '',
            }
        default:
            return { ...state }
    }
}
