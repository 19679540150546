import React, { FunctionComponent } from 'react'
import { Box, Flex, HStack, IconButton, Text, Tooltip, useMediaQuery, useToast } from '@chakra-ui/react'
import Colors from '../../theme/Colors'
import { useTranslation } from 'react-i18next'
import { ExecutionType } from '../../../domain/models/ExecutionType'
import { AiOutlineCopy } from 'react-icons/ai'
import { Moment } from 'moment'
import { cleanUrl } from '../../../utils/fucntions'
import useWindowDimensions from '../../../utils/useWindowDimensions'
import { ExecutionTrackEvents } from '../../../utils/trackEvents'
import mixpanel from 'mixpanel-browser'
import { ExecutionMode } from '../../../domain/models/ExecutionMode'

interface Props {
    websiteUrl: string
    message: string
    executedAt: Moment
    type: ExecutionType
    mode: ExecutionMode
    onClick: () => void
}

const ExecutionItem: FunctionComponent<Props> = ({ websiteUrl, message, executedAt, type, mode, onClick }) => {
    const toast = useToast()
    const { t } = useTranslation()
    const { width } = useWindowDimensions()
    const [isMobileOrLarger] = useMediaQuery('(min-width: 479px)')

    const getExecutionColor = (type: ExecutionType) => {
        switch (type) {
            case ExecutionType.MANUAL:
                return '#9F7AEA'
            case ExecutionType.API:
                return '#00A3C4'
            case ExecutionType.MAKE:
                return '#FF00FF'
            case ExecutionType.ZAPIER:
                return '#FF4F00'
        }
    }

    const getExecutionName = (type: ExecutionType) => {
        switch (type) {
            case ExecutionType.MANUAL:
                return t('executionItem.fromScraptio')
            case ExecutionType.API:
                return t('executionItem.fromAPI')
            case ExecutionType.MAKE:
                return t('executionItem.fromMake')
            case ExecutionType.ZAPIER:
                return t('executionItem.fromZapier')
        }
    }

    const getModeName = (mode: ExecutionMode) => {
        if (mode === ExecutionMode.LINKS_ABSOLUTE || mode === ExecutionMode.LINKS_RELATIVE) {
            return t('executionItem.links')
        } else if (mode === ExecutionMode.EMAILS) {
            return t('executionItem.emails')
        } else {
            return t('executionItem.texts')
        }
    }

    const handleCopyResults = () => {
        navigator.clipboard.writeText(message)
        mixpanel.track(ExecutionTrackEvents.EXECUTION_ITEM_COPIED)
        toast({
            title: t('successFeedback.copy.title'),
            description: t('successFeedback.copy.description'),
            status: 'success',
            position: 'bottom',
            duration: 3000,
            isClosable: true,
        })
    }

    const renderDateSection = () => {
        const content = (
            <>
                <Text fontSize={'sm'} color={Colors.textSecondary}>
                    {`${executedAt.format('DD/MM/YYYY - HH:mm')} / ${getModeName(mode)} /`}
                </Text>
                <Text fontSize={'sm'} fontWeight={'bold'} color={getExecutionColor(type)}>
                    {getExecutionName(type)}
                </Text>
            </>
        )

        return isMobileOrLarger ? <HStack>{content}</HStack> : <Box>{content}</Box>
    }

    return (
        <Box
            backgroundColor={Colors.whiteOpacity}
            borderRadius={12}
            my={'12px'}
            p={4}
            cursor={'pointer'}
            transition={'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform'}
            transitionDuration={'0.2s'}
            border={`1px solid #FFFFFF00`}
            _hover={{
                backgroundColor: Colors.white,
                border: `1px solid ${Colors.primary['300']}`,
            }}
            onClick={onClick}>
            <Flex justifyContent={'space-between'} alignItems={'center'}>
                <Box>
                    <Text
                        fontSize={'lg'}
                        fontWeight={'bold'}
                        color={Colors.text}
                        noOfLines={1}
                        w={Math.min(width - 180, 1000)}>
                        {cleanUrl(websiteUrl)}
                    </Text>
                    {renderDateSection()}
                </Box>
                <Box>
                    <Tooltip label={t('executionItem.copyTooltip')} placement={'left'} openDelay={500}>
                        <IconButton
                            color={Colors.text}
                            colorScheme="ctas"
                            aria-label={t('executionItem.copyTooltip')}
                            icon={<AiOutlineCopy />}
                            size={'md'}
                            onClick={(event) => {
                                event.stopPropagation()
                                handleCopyResults()
                            }}
                        />
                    </Tooltip>
                </Box>
            </Flex>
        </Box>
    )
}

export default ExecutionItem
