import { Execution } from '../../domain/models/Execution'
import { ExecutionType } from '../../domain/models/ExecutionType'
import moment from 'moment'
import { getSupabaseSession } from '../services/supabaseService'
import { axiosInstance } from '../services/axiosService'
import { ExecutionMode } from '../../domain/models/ExecutionMode'

export interface ScrapeRepositoryType {
    scrapeWebsite: (
        websiteUrl: string,
        filters: string[],
        mode: ExecutionMode,
        matchAllFilters: boolean,
    ) => Promise<Execution>
}

export const scrapeRepository = (): ScrapeRepositoryType => {
    const scrapeWebsite: ScrapeRepositoryType['scrapeWebsite'] = async (
        websiteUrl,
        filters,
        mode,
        matchAllFilters,
    ): Promise<Execution> => {
        try {
            const supabaseSession = await getSupabaseSession()
            if (supabaseSession) {
                const token = supabaseSession.access_token
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }

                const result = await axiosInstance.post(
                    'https://prxkimbjxolcwkauhvdi.functions.supabase.co/scrape-from-scraptio',
                    { url: websiteUrl, filters: filters, match_all: matchAllFilters, mode: mode },
                    { headers },
                )

                if (result?.data.error) {
                    throw new Error(result?.data.error)
                }

                return {
                    id: Math.floor(Math.random() * 1000000000).toString(),
                    websiteUrl,
                    resultCode: result.status.toString(),
                    resultMessage:
                        mode === ExecutionMode.TEXTS ? result?.data?.data : JSON.stringify(result?.data?.data),
                    origin: ExecutionType.MANUAL,
                    mode: mode,
                    createdAt: moment(),
                }
                return result?.data?.data
            }

            throw new Error('No session')
        } catch (e: any) {
            console.log(e)
            throw new Error(e.message)
        }
    }

    return {
        scrapeWebsite,
    }
}
