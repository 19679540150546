import { initialState } from '../initialState'
import { Execution } from '../../models/Execution'
import {
    HistoryActionReduxTypes,
    SET_HISTORY_ERROR_ACTION,
    UPDATE_HISTORY_DATA_ACTION,
} from '../../actions/history/historyActions.redux'

export interface HistoryReducerType {
    userHistory: Execution[]
    error: string
}

export const historyReducer = (
    state: HistoryReducerType = initialState.historyReducer,
    action: HistoryActionReduxTypes,
): HistoryReducerType => {
    switch (action.type) {
        case UPDATE_HISTORY_DATA_ACTION:
            return {
                ...state,
                userHistory: action.payload.userHistory,
            }
        case SET_HISTORY_ERROR_ACTION:
            return {
                ...state,
                error: action.payload.error,
            }
        default:
            return { ...state }
    }
}
