import { ApiKeyEntity } from '../models/ApiKeyEntity'
import { ApiKey } from '../../domain/models/ApiKey'
import moment from 'moment'

export const apiKeyParser = {
    toDomain: (entity: ApiKeyEntity): ApiKey => {
        return {
            id: entity.id,
            title: entity.title,
            apiKey: entity.api_key || '',
            createdAt: moment(entity.created_at),
            expiresAt: entity.expires_at ? moment(entity.expires_at) : null,
        }
    },
}
