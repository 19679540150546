import React, { FunctionComponent, useRef } from 'react'
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import ExecutionDetailDialogContent from './ExecutionDetailDialogContent'
import { ExecutionMode } from '../../../domain/models/ExecutionMode'

interface Props {
    isOpen: boolean
    onClose: () => void
    websiteUrl: string
    content: string
    mode: ExecutionMode
}

const ExecutionDetailDialog: FunctionComponent<Props> = ({ isOpen, onClose, websiteUrl, content, mode }) => {
    const initialRef = useRef(null)

    return (
        <Modal initialFocusRef={initialRef} size={'xl'} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent marginX={4}>
                <ExecutionDetailDialogContent websiteUrl={websiteUrl} content={content} mode={mode} onClose={onClose} />
            </ModalContent>
        </Modal>
    )
}

export default ExecutionDetailDialog
