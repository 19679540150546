export const AARRRTrackEvents = {
    ACQ_LANDING_PAGE_VISIT: 'ACQ - Landing page visit',
    ACQ_SIGNUP_PAGE_VISIT: 'ACQ - Signup page visit',
    ACQ_SIGNUP_DONE: 'ACQ - Signup done',
    ACT_EXECUTION: 'ACT - Execution',
    ACT_CREATE_API_KEY: 'ACT - Create API key',
    RET_CHECKOUT_PAGE_VISIT: 'RET - Checkout page visit',
    REV_PURCHASE_PLAN: 'REV - Purchase plan', // TODO Test that work properly.
    REV_CANCEL_PLAN: 'REV - Cancel plan', // TODO Add on Stripe webhook.
}

export const AuthTrackEvents = {
    AUTH_PAGE_VISIT: 'AUTH - Auth page visit',
    TOC_LINK_CLICKED: 'AUTH - Toc link clicked',
    PRIVACY_LINK_CLICKED: 'AUTH - Privacy link clicked',
    SIGNED_IN: 'AUTH - Signed in',
    LOGOUT: 'AUTH - Logout',
}

export const ScrapeTrackEvents = {
    SCRAPE_BANNER_CLICKED: 'SCRAPE - Scrape banner clicked',
    SCRAPE_BUTTON_CLICKED: 'SCRAPE - Scrape button clicked',
    SCRAPE_VALIDATION_CORRECT: 'SCRAPE - Validation correct',
    SCRAPE_VALIDATION_INCORRECT: 'SCRAPE - Validation incorrect',
    SCRAPE_SUCCESS: 'SCRAPE - Scrape success',
    SCRAPE_FAILURE: 'SCRAPE - Scrape failure',
}

export const ApiKeyTrackEvents = {
    APIKEY_ADD_KEY_CLICKED: 'APIKEY - Add key clicked',
    APIKEY_CREATE_CLICKED: 'APIKEY - Create clicked',
    APIKEY_COPY_CLICKED: 'APIKEY - Copy clicked',
}

export const ExecutionTrackEvents = {
    EXECUTION_ITEM_CLICKED: 'EXECUTIONS - Execution item clicked',
    EXECUTION_ITEM_COPIED: 'EXECUTIONS - Execution item copied',
    EXECUTION_ITEM_WEBSITE_CLICKED: 'EXECUTIONS - Execution item website clicked',
}

export const SupportTrackEvents = {
    SUPPORT_BUTTON_CLICKED: 'SUPPORT - Support button clicked',
}

export const PaymentTrackEvents = {
    PAYMENTS_SUCCESS_PAGE_VISIT: 'PAYMENTS - Success page visit',
    PAYMENTS_UPGRADE_BANNER_CLICKED: 'PAYMENTS - Upgrade banner clicked',
    PAYMENTS_UPGRADE_BUTTON_CLICKED: 'PAYMENTS - Header upgrade button clicked',
    PAYMENTS_UPGRADE_TO_PRO_BUTTON_CLICKED: 'PAYMENTS - Header upgrade to pro button clicked',
    PAYMENTS_UPGRADE_TO_ULTRA_BUTTON_CLICKED: 'PAYMENTS - Header upgrade to ultra button clicked',
    PAYMENTS_NO_CREDITS_MESSAGE_CLICKED: 'PAYMENTS - No credits message clicked',
    PAYMENTS_CUSTOMER_PORTAL_CLICKED: 'PAYMENTS - Customer portal clicked',
}
