import React, { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    Center,
    HStack,
    Spinner,
    Text,
    useDisclosure,
    useToast,
} from '@chakra-ui/react'
import Colors from '../../theme/Colors'
import ApiKeyItem from './ApiKeyItem'
import { apiKeysActions } from '../../../domain/actions/apiKeys/apiKeysActions'
import { useDispatch, useSelector } from 'react-redux'
import { StateType } from '../../../domain/state/types'
import NewKeyDialog from './NewKeyDialog'
import mixpanel from 'mixpanel-browser'
import { ApiKeyTrackEvents, ScrapeTrackEvents } from '../../../utils/trackEvents'

const ApiKeySection: FunctionComponent = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const toast = useToast()
    const cancelRef = React.useRef(null)
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [apiKeyToDelete, setApiKeyToDelete] = useState<string | null>(null)
    const [showConfirmApiDeletionDialog, setShowConfirmApiDeletionDialog] = useState(false)

    const { apiKeys, error: apiKeysError } = useSelector((state: StateType) => {
        return state.apiKeysReducer
    })

    useEffect(() => {
        if (apiKeysError) {
            toast({
                title: t('error.apiKeysNotLoaded.title'),
                description: t('error.apiKeysNotLoaded.description'),
                status: 'error',
                position: 'bottom',
                duration: 3000,
                isClosable: true,
            })
        }
    }, [apiKeysError])

    const handleDeleteApiKeyButtonClicked = (id: string) => {
        setApiKeyToDelete(id)
        setShowConfirmApiDeletionDialog(true)
    }

    const deleteApiKey = () => {
        setShowConfirmApiDeletionDialog(false)
        if (apiKeyToDelete) {
            apiKeysActions(dispatch).deleteApiKey(apiKeyToDelete)
        }
        setApiKeyToDelete(null)
    }

    const renderApiKeySection = () => {
        if (apiKeys && apiKeys.length > 0) {
            return apiKeys.map((apiKey) => {
                return (
                    <ApiKeyItem
                        key={apiKey.id}
                        name={apiKey.title}
                        keyHint={apiKey.apiKey}
                        createdAt={apiKey.createdAt}
                        expiresAt={apiKey.expiresAt}
                        onDeleteClick={() => handleDeleteApiKeyButtonClicked(apiKey.id)}
                    />
                )
            })
        }

        return (
            <Text fontSize={'lg'} color={Colors.textSecondary} mt={'16px'} mb={'32px'}>
                {t('apiKeySection.empty')}
            </Text>
        )
    }

    return (
        <>
            <Box mt={'24px'}>
                <HStack justifyContent={'space-between'}>
                    <Text fontSize={['xl', '2xl']} fontWeight={'bold'} color={Colors.text}>
                        {t('apiKeySection.title')}
                    </Text>
                    <Button
                        colorScheme={'brandButton'}
                        variant="outline"
                        onClick={() => {
                            mixpanel.track(ApiKeyTrackEvents.APIKEY_ADD_KEY_CLICKED)
                            onOpen()
                        }}>
                        {t('apiKeySection.new')}
                    </Button>
                </HStack>
                {apiKeys ? (
                    renderApiKeySection()
                ) : (
                    <Center h="100%" mb={'80px'}>
                        <Spinner color={Colors.primary['600']} size="lg" />
                    </Center>
                )}
            </Box>
            <NewKeyDialog
                isOpen={isOpen}
                onClose={() => {
                    onClose()
                    apiKeysActions(dispatch).getApiKeys()
                }}
            />
            <AlertDialog isOpen={showConfirmApiDeletionDialog} leastDestructiveRef={cancelRef} onClose={() => null}>
                <AlertDialogOverlay>
                    <AlertDialogContent marginX={4}>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            {t('deleteDialog.title')}
                        </AlertDialogHeader>
                        <AlertDialogBody>{t('deleteDialog.description')}</AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => setShowConfirmApiDeletionDialog(false)}>
                                {t('common.cancel')}
                            </Button>
                            <Button
                                colorScheme="red"
                                onClick={() => {
                                    deleteApiKey()
                                }}
                                ml={3}>
                                {t('deleteDialog.delete')}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default ApiKeySection
