import React, { FunctionComponent } from 'react'
import { Box, Progress, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import Colors from '../theme/Colors'
import mixpanel from 'mixpanel-browser'
import { PaymentTrackEvents } from '../../utils/trackEvents'

interface Props {
    max: number
    current: number
    openUpgradeDialog: () => void
}

const QuotaBar: FunctionComponent<Props> = ({ max, current, openUpgradeDialog }) => {
    const { t } = useTranslation()

    const handleUpgradeItemClicked = async () => {
        mixpanel.track(PaymentTrackEvents.PAYMENTS_NO_CREDITS_MESSAGE_CLICKED)
        openUpgradeDialog()
    }

    const getColorScheme = () => {
        if (current > max * 0.25) return 'brand'
        else if (current > max * 0.1) return 'orange'
        else return 'red'
    }

    const renderProgessBarOrUpgrade = () => {
        if (current > 0) {
            return (
                <Progress
                    w={120}
                    value={current}
                    max={max}
                    size="sm"
                    borderRadius={12}
                    colorScheme={getColorScheme()}
                />
            )
        }

        return (
            <Text fontSize="sm" mt={'-2px'}>
                {t('header.upgradePlan')}
            </Text>
        )
    }

    return (
        <Box
            mr={4}
            mt={-1}
            cursor={'pointer'}
            color={Colors.primary['900']}
            _hover={{
                color: Colors.primary['600'],
            }}
            onClick={() => {
                if (current <= 0) {
                    handleUpgradeItemClicked()
                }
            }}>
            <Text fontSize="sm" color={Colors.text}>
                {t('header.creditsLeft', { credits: current.toLocaleString('en-US').replace(/,/g, '.') })}
            </Text>
            {renderProgessBarOrUpgrade()}
        </Box>
    )
}

export default QuotaBar
