import { supabase } from '../services/supabaseService'
import { ApiKey } from '../../domain/models/ApiKey'
import { apiKeyParser } from '../parsers/apiKeyParser'

export interface ApiKeysRepositoryType {
    getApiKeys: () => Promise<ApiKey[]>
    createNewApiKey: (title: string) => Promise<ApiKey>
    deleteApiKey: (id: string) => Promise<void>
}

export const apiKeysRepository = (): ApiKeysRepositoryType => {
    const generateApiKey = (): string => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        const length = 64
        let apiKey = ''
        for (let i = 0; i < length; i++) {
            apiKey += characters.charAt(Math.floor(Math.random() * characters.length))
        }
        return apiKey
    }

    const getApiKeys: ApiKeysRepositoryType['getApiKeys'] = async (): Promise<ApiKey[]> => {
        const user = await supabase.auth.getUser()
        if (user?.data?.user) {
            const { data, error } = await supabase.from('keys').select('id, title, created_at, expires_at')

            if (data) {
                return data.map((item) => {
                    return apiKeyParser.toDomain(item)
                })
            }
        }

        throw new Error('Cannot get API keys')
    }

    const createNewApiKey: ApiKeysRepositoryType['createNewApiKey'] = async (title): Promise<ApiKey> => {
        const user = await supabase.auth.getUser()
        if (user?.data?.user) {
            const { data, error } = await supabase
                .from('keys')
                .insert([{ title: title, api_key: generateApiKey(), user_id: user.data.user.id }])
                .select('id, title, api_key, created_at, expires_at')

            if (data && data.length > 0) {
                return apiKeyParser.toDomain(data[0])
            }
        }

        throw new Error('Cannot add used credits')
    }

    const deleteApiKey: ApiKeysRepositoryType['deleteApiKey'] = async (id): Promise<void> => {
        const user = await supabase.auth.getUser()
        if (user?.data?.user) {
            const { error } = await supabase.from('keys').delete().eq('id', id)
            if (!error) {
                return
            }
        }

        throw new Error('Cannot delete API key')
    }

    return {
        getApiKeys,
        createNewApiKey,
        deleteApiKey,
    }
}
