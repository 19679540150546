import React, { FunctionComponent } from 'react'
import { Box, Center, Spinner } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { StateType } from '../../domain/state/types'
import Colors from '../theme/Colors'

const LoadingDialog: FunctionComponent = () => {
    const isLoadingStripe = useSelector((state: StateType) => {
        return state.subscriptionReducer.isLoadingStripe
    })

    return isLoadingStripe ? (
        <Box position={'absolute'} top={0} left={0} right={0} bottom={0} zIndex={100} bg={'rgba(0, 0, 0, 0.48)'}>
            <Center h="100%">
                <Spinner color={Colors.primary['600']} size="xl" />
            </Center>
        </Box>
    ) : null
}

export default LoadingDialog
