import React, { useEffect } from 'react'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import MainScreen from './presentation/screens/main'
import './App.css'
import './data/services/supabaseService'
import AuthScreen from './presentation/screens/auth'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import BrandTheme from './presentation/theme/BrandTheme'
import configureStore from './domain/store'
import { initialState } from './domain/state/initialState'
import { Provider } from 'react-redux'
import './services/localization/i18n'
import SuccessScreen from './presentation/screens/success/index'
import RecoverPasswordScreen from './presentation/screens/recover'
import { supabase } from './data/services/supabaseService'

const store = configureStore(initialState)
const theme = extendTheme(BrandTheme)

export const App = () => {
    useEffect(() => {
        const listener = supabase.auth.onAuthStateChange((event, session) => {
            if (event == 'PASSWORD_RECOVERY') {
                window.location.href = '/recover-password'
            }
        })

        return () => {
            listener.data.subscription.unsubscribe()
        }
    }, [])

    return (
        <Provider store={store}>
            <ChakraProvider theme={theme}>
                <BrowserRouter>
                    <Switch>
                        <Route path={`/login`} component={AuthScreen} />
                        <Route path={`/recover-password`} component={RecoverPasswordScreen} />
                        <Route path={`/success`} component={SuccessScreen} />
                        <Route path={`/`} component={MainScreen} />
                    </Switch>
                </BrowserRouter>
            </ChakraProvider>
        </Provider>
    )
}
