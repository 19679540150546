import { Dispatch } from 'redux'
import {
    ADD_NEW_API_KEY_ACTION,
    ADD_NEW_API_KEY_ERROR_ACTION,
    AddNewApiKeyAction,
    AddNewApiKeyErrorAction,
    CLEAR_NEW_API_KEY_ACTION,
    ClearNewApiKeyAction,
    SET_API_KEYS_ACTION,
    SET_API_KEYS_ERROR_ACTION,
    SetApiKeysDataAction,
    SetApiKeysErrorAction,
} from './apiKeysActions.redux'
import { apiKeysRepository } from '../../../data/repositories/apiKeysRepository'
import mixpanel from 'mixpanel-browser'
import { AARRRTrackEvents } from '../../../utils/trackEvents'

export type ApiKeysActionsType = {
    getApiKeys: () => void
    createNewApiKey: (title: string) => void
    clearNewApiKey: () => void
    deleteApiKey: (id: string) => void
}

export const apiKeysActions = (dispatch: Dispatch): ApiKeysActionsType => {
    const getApiKeys: ApiKeysActionsType['getApiKeys'] = async () => {
        dispatch<SetApiKeysErrorAction>({
            type: SET_API_KEYS_ERROR_ACTION,
            payload: {
                error: '',
            },
        })

        try {
            const apiKeys = await apiKeysRepository().getApiKeys()
            dispatch<SetApiKeysDataAction>({
                type: SET_API_KEYS_ACTION,
                payload: {
                    apiKeys,
                },
            })
        } catch (e: any) {
            console.error(e)
            dispatch<SetApiKeysErrorAction>({
                type: SET_API_KEYS_ERROR_ACTION,
                payload: {
                    error: e.message,
                },
            })
        }
    }

    const createNewApiKey: ApiKeysActionsType['createNewApiKey'] = async (title) => {
        dispatch<AddNewApiKeyErrorAction>({
            type: ADD_NEW_API_KEY_ERROR_ACTION,
            payload: {
                error: '',
            },
        })

        try {
            const apiKey = await apiKeysRepository().createNewApiKey(title)

            mixpanel.track(AARRRTrackEvents.ACT_CREATE_API_KEY)
            dispatch<AddNewApiKeyAction>({
                type: ADD_NEW_API_KEY_ACTION,
                payload: {
                    apiKey,
                },
            })
        } catch (e: any) {
            console.error(e)
            dispatch<AddNewApiKeyErrorAction>({
                type: ADD_NEW_API_KEY_ERROR_ACTION,
                payload: {
                    error: e.message,
                },
            })
        }
    }

    const clearNewApiKey: ApiKeysActionsType['clearNewApiKey'] = async () => {
        dispatch<ClearNewApiKeyAction>({
            type: CLEAR_NEW_API_KEY_ACTION,
        })
    }

    const deleteApiKey: ApiKeysActionsType['deleteApiKey'] = async (id) => {
        await apiKeysRepository().deleteApiKey(id)
        getApiKeys()
    }

    return {
        getApiKeys,
        createNewApiKey,
        clearNewApiKey,
        deleteApiKey,
    }
}
