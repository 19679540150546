// ACTION TYPES
import { Subscription } from '../../models/Subscription'

export const UPDATE_SUBSCRIPTION_DATA_ACTION = 'UPDATE_SUBSCRIPTION_DATA_ACTION'
export const SET_LOADING_STRIPE = 'SET_LOADING_STRIPE'

export interface UpdateSubscriptionDataAction {
    type: typeof UPDATE_SUBSCRIPTION_DATA_ACTION
    payload: {
        subscription: Subscription
    }
}

export interface SetLoadingStripeAction {
    type: typeof SET_LOADING_STRIPE
    payload: {
        isLoadingStripe: boolean
    }
}

export type SubscriptionActionReduxTypes = UpdateSubscriptionDataAction | SetLoadingStripeAction
