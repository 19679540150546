import { supabase } from '../services/supabaseService'
import { Execution } from '../../domain/models/Execution'
import { executionParser } from '../parsers/executionParser'
import { ExecutionEntity } from '../models/ExecutionEntity'

export interface ExecutionRepositoryType {
    getExecutions: (offset: number | null) => Promise<Execution[]>
}

const PAGE_SIZE = 250

export const executionRepository = (): ExecutionRepositoryType => {
    const getExecutions: ExecutionRepositoryType['getExecutions'] = async (offset): Promise<Execution[]> => {
        const user = await supabase.auth.getUser()
        if (user?.data?.user) {
            const { data, error } = await supabase
                .from('executions')
                .select('*')
                .order('created_at', { ascending: false })
                .range(offset || 0, offset ? offset + PAGE_SIZE : PAGE_SIZE)

            if (data) {
                return (data as ExecutionEntity[]).map((item) => {
                    return executionParser.toDomain(item)
                })
            }
        }

        throw new Error('Cannot get Executions')
    }

    return {
        getExecutions,
    }
}
