import React, { FunctionComponent } from 'react'
import { Center, Flex, GridItem, Text } from '@chakra-ui/react'
import Colors from '../theme/Colors'
import { useTranslation } from 'react-i18next'
import { Subscription } from '../../domain/models/Subscription'

interface Props {
    subscription: Subscription | null
    onClick: () => void
}

const ScrapeButton: FunctionComponent<Props> = ({ subscription, onClick }) => {
    const { t } = useTranslation()

    return (
        <>
            <GridItem
                colSpan={subscription && subscription.plan === 0 ? [3, 3, 1] : 3}
                backgroundColor={Colors.whiteOpacity}
                borderRadius={12}
                p={4}
                cursor={'pointer'}
                transition={'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform'}
                transitionDuration={'0.2s'}
                border={`1px solid #FFFFFF00`}
                _hover={{
                    backgroundColor: Colors.white,
                    border: `1px solid ${Colors.primary['300']}`,
                }}
                onClick={onClick}>
                <Flex h={'100%'} direction={'column'} justifyContent={'center'} alignItems={'center'}>
                    <Flex justifyContent={'space-between'}>
                        <Center h={'44px'} w={'44px'} borderRadius={'22px'} backgroundColor={Colors.primary['100']}>
                            <Text fontSize={'2xl'}>🚀</Text>
                        </Center>
                    </Flex>
                    <Text fontSize={'xl'} fontWeight={'bold'} mt={2} color={Colors.text}>
                        {t('scrapeButton')}
                    </Text>
                </Flex>
            </GridItem>
        </>
    )
}

export default ScrapeButton
