import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Box, Button, Center, Spinner, Text, useDisclosure, useToast} from '@chakra-ui/react'
import Colors from '../../theme/Colors'
import {useDispatch, useSelector} from 'react-redux'
import {StateType} from '../../../domain/state/types'
import ExecutionDetailDialog from './ExecutionDetailDialog'
import ExecutionItem from './ExecutionItem'
import {Execution} from '../../../domain/models/Execution'
import mixpanel from 'mixpanel-browser'
import {ExecutionTrackEvents} from '../../../utils/trackEvents'
import {ExecutionMode} from '../../../domain/models/ExecutionMode'
import {getSupabaseSession} from "../../../data/services/supabaseService";
import {executionActions} from "../../../domain/actions/execution/executionActions";

const ExecutionHistorySection: FunctionComponent = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [loadingMore, setLoadingMore] = useState(true)
    const [selectedExecution, setSelectedExecution] = useState<Execution | null>(null)

    const { executions, executionsOffset, error: executionsError } = useSelector((state: StateType) => {
        return state.executionReducer
    })

    useEffect(() => {
        if (executionsError) {
            toast({
                title: t('error.historyNotLoaded.title'),
                description: t('error.historyNotLoaded.description'),
                status: 'error',
                position: 'bottom',
                duration: 3000,
                isClosable: true,
            })
        }
    }, [executionsError])

    useEffect(() => {
        setLoadingMore(false)
    }, [executions, executionsOffset]);

    const loadMoreExecutions = () => {
        if (!loadingMore) {
            getSupabaseSession(history).then((session) => {
                if (session) {
                    setLoadingMore(true)
                    executionActions(dispatch).getExecutions(executionsOffset !== null ? executionsOffset : 0)
                }
            })
        }
    }

    const renderExecutionsSection = () => {
        if (executions && executions.length > 0) {
            return executions.map((execution) => {
                return (
                    <ExecutionItem
                        key={execution.id}
                        websiteUrl={execution.websiteUrl}
                        message={execution.resultMessage}
                        executedAt={execution.createdAt}
                        type={execution.origin}
                        mode={execution.mode}
                        onClick={() => {
                            setSelectedExecution(execution)
                            mixpanel.track(ExecutionTrackEvents.EXECUTION_ITEM_CLICKED)
                            onOpen()
                        }}
                    />
                )
            })
        }

        return (
            <Text fontSize={'lg'} color={Colors.textSecondary} mt={'16px'} mb={'32px'}>
                {t('executionSection.empty')}
            </Text>
        )
    }

    return (
        <>
            <Box mt={'24px'}>
                <Text fontSize={['xl', '2xl']} fontWeight={'bold'} color={Colors.text}>
                    {t('executionSection.title')}
                </Text>
                {executions ? (
                    <>
                        {renderExecutionsSection()}
                        <div style={{ textAlign: "center" }}>
                            <Button
                                colorScheme={'brandButton'}
                                variant="outline"
                                onClick={() => {
                                    loadMoreExecutions()
                                }}>
                                <div style={{ width: 160 }}>
                                    {loadingMore ? <Spinner color={Colors.primary['600']} size="sm" /> : t('executionSection.loadMore')}
                                </div>
                            </Button>
                        </div>
                    </>
                ) : (
                    <Center h="100%" mb={'80px'}>
                        <Spinner color={Colors.primary['600']} size="lg" />
                    </Center>
                )}
            </Box>
            <ExecutionDetailDialog
                isOpen={isOpen}
                onClose={onClose}
                websiteUrl={selectedExecution?.websiteUrl || ''}
                content={selectedExecution?.resultMessage || ''}
                mode={selectedExecution?.mode || ExecutionMode.TEXTS}
            />
        </>
    )
}

export default ExecutionHistorySection
