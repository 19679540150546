import { StateType } from './types'

export const initialState: StateType = {
    subscriptionReducer: {
        subscriptionData: null,
        creditsAvailable: true,
        remainingCredits: 0,
        isLoadingStripe: false,
    },
    historyReducer: {
        userHistory: [],
        error: '',
    },
    apiKeysReducer: {
        apiKeys: null,
        error: '',
        newApiKey: null,
        newApiKeyError: '',
    },
    executionReducer: {
        executions: null,
        executionsOffset: 0,
        error: '',
    },
    scraperReducer: {
        scrapeResult: null,
        error: '',
    },
}
