import { initialState } from '../initialState'
import {
    ExecutionActionReduxTypes,
    SET_EXECUTIONS_ACTION,
    SET_EXECUTIONS_ERROR_ACTION,
} from '../../actions/execution/executionActions.redux'
import { Execution } from '../../models/Execution'

export interface ExecutionReducerType {
    executions: Execution[] | null
    executionsOffset: number
    error: string
}

export const executionReducer = (
    state: ExecutionReducerType = initialState.executionReducer,
    action: ExecutionActionReduxTypes,
): ExecutionReducerType => {
    switch (action.type) {
        case SET_EXECUTIONS_ACTION:
            return {
                ...state,
                executions: state.executions && !action.payload.reload ? [...state.executions, ...action.payload.executions] : [...action.payload.executions],
                executionsOffset: action.payload.offset
            }
        case SET_EXECUTIONS_ERROR_ACTION:
            return {
                ...state,
                error: action.payload.error,
            }
        default:
            return { ...state }
    }
}
