export const Colors = {
    primary: {
        100: '#f0ebff',
        300: '#e6deff',
        500: '#CBBFF9',
        600: '#A894F1',
        900: '#8C71EC',
    },
    backgroundGradient: {
        left: '#f0ebff',
        right: '#e6deff',
    },
    white: '#FFFFFF',
    whiteOpacity: 'rgba(255,255,255,0.8)',
    divider: 'rgba(0,0,0,0.1)',
    text: '#334155',
    textSecondary: '#64748B',
    iconBackground: '#F1F5F9BF',
}

export default Colors
