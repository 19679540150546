import {Box, Flex, Grid, useDisclosure} from '@chakra-ui/react'
import {MainLayout} from '../../layouts/MainLayout'
import * as React from 'react'
import {useEffect} from 'react'
import {getSupabaseSession, supabase} from '../../../data/services/supabaseService'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {subscriptionActions} from '../../../domain/actions/subscription/subscriptionActions'
import {StateType} from '../../../domain/state/types'
import {apiKeysActions} from '../../../domain/actions/apiKeys/apiKeysActions'
import {executionActions} from '../../../domain/actions/execution/executionActions'
import ScrapeButton from '../../components/ScrapeButton'
import ApiKeySection from '../../components/ApiKeySection/ApiKeySection'
import ExecutionHistorySection from '../../components/ExecutionHistorySection/ExecutionHistorySection'
import NewScrapeDialog from '../../components/NeyScrapeDialog'
import mixpanel from 'mixpanel-browser'
import {AARRRTrackEvents, ScrapeTrackEvents} from '../../../utils/trackEvents'
import PremiumBanner from '../../components/PremiumBanner'

const MainScreen = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { isOpen, onOpen, onClose } = useDisclosure()

    const { subscriptionData } = useSelector((state: StateType) => {
        return state.subscriptionReducer
    })

    useEffect(() => {
        const listener = supabase.auth.onAuthStateChange((event, session) => {
            if (event == 'SIGNED_IN') {
                if (session) {
                    mixpanel.identify(session.user.id)
                    mixpanel.track(AARRRTrackEvents.ACQ_SIGNUP_DONE)
                    history.push('/')
                }
            }
        })

        return () => {
            listener.data.subscription.unsubscribe()
        }
    }, [])

    useEffect(() => {
        getSupabaseSession(history).then((session) => {
            if (session) {
                subscriptionActions(dispatch).getSubscriptionData()
                apiKeysActions(dispatch).getApiKeys()
                executionActions(dispatch).getExecutions(0)
            }
        })
    }, [])

    const handleScrapeButtonClicked = () => {
        mixpanel.track(ScrapeTrackEvents.SCRAPE_BANNER_CLICKED)
        onOpen()
    }

    return (
        <MainLayout>
            <Flex w={'100%'} direction={'column'}>
                <Box>
                    <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                        <ScrapeButton subscription={subscriptionData} onClick={handleScrapeButtonClicked} />
                        {subscriptionData && subscriptionData.plan === 0 && <PremiumBanner />}
                    </Grid>
                </Box>
                <ApiKeySection />
                <ExecutionHistorySection />
                <NewScrapeDialog isOpen={isOpen} onClose={onClose} />
            </Flex>
        </MainLayout>
    )
}

export default MainScreen
